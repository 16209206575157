import { Trans } from "@lingui/macro";
import React, { memo } from "react";
import { Icon } from "../Icon";
import "./AudioGuideSection.css";

const AudioGuideBlock = memo(({ icon, title, subtitle }) => (
  <div className="AudioGuideSection__block">
    <Icon
      name={icon.name}
      height={icon.height}
      width={icon.width}
      className="AudioGuideSection__block_icon"
    />
    <div className="AudioGuideSection__block_text">
      <h2 className="AudioGuideSection__block_text-title">{title}</h2>
      <p className="AudioGuideSection__block_text-subtitle">{subtitle}</p>
    </div>
  </div>
));

const AudioGuideSection = memo(() => {
  const blocks = [
    {
      icon: { name: "hand-phone", height: "90", width: "60" },
      title: <Trans>Audio tours on your smartphone</Trans>,
      subtitle: (
        <Trans>
          After purchase, you will receive the link to download the app to take this tour.
        </Trans>
      ),
    },
    {
      icon: { name: "control-check", height: "90", width: "65" },
      title: <Trans>Control your experience</Trans>,
      subtitle: (
        <Trans>
          With the in-app audio guide, you decide when to start, pause, and finish your tour.
        </Trans>
      ),
    },
    {
      icon: { name: "martian", height: "90", width: "90" },
      title: <Trans>No internet is required</Trans>,
      subtitle: (
        <Trans>All audio tours and tickets can be downloaded to your device anytime.</Trans>
      ),
    },
  ];

  return (
    <div className="AudioGuideSection">
      {blocks.map((block, index) => (
        <AudioGuideBlock
          key={index}
          icon={block.icon}
          title={block.title}
          subtitle={block.subtitle}
        />
      ))}
    </div>
  );
});

export default AudioGuideSection;
