import React, { useState, Children } from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import classNames from "classnames";
import { Icon } from "../../Icon";
import Button from "../../Button";
import "./withArrows.css";

import useCurrentLanguage from "../../../functions/languages/useCurrentLanguage";

/**
 * Sizes of arrows in dependance with component's size
 * @type {Object}
 */
const arrowSizes = {
  default: "24",
  small: "16",
};

/**
 * Adds navigation buttons to the slider
 * @param {React::Component} ProductsSlider - modified component
 */
export default ProductsSlider =>
  /**
   * ProductsSlider with navigation arrows
   * Removes ability to drag slides, fixes empty space swipes
   * @param {Object} $
   * @param {String} $.size - additional sizes of the component (`"small"`)
   * @param {Number} $.slidesPerPage - amount of slides shown on the page
   * @param {Array[React::Component]} $.children - slides in the component
   * @param {Array[React::Component]} $.additional - additional children, rendering before carousel
   * @param {Boolean} $.arrows - show/hide arrows
   * @param {Boolean} $.disableArrowsAndScrolling - disable arrows and card scrolling
   * @param {Function} $.onChange - calls when first visible slide changes
   */
  ({
    children = [],
    additional,
    arrows = true,
    slidesPerPage,
    slidesToScroll = 1,
    onChange = () => {},
    arrowsMini = false,
    title,
    size,
    disableArrowsAndScrolling = false,
    ...props
  }) => {
    const slidesAmount = Children.count(children);
    const arrowsEnabled =
      !disableArrowsAndScrolling && arrows && slidesAmount > (slidesPerPage || 1);
    const arrowSize = arrowSizes[size] || arrowSizes.default;
    const [current, setCurrent] = useState(0);
    const isDesktop = useMediaQuery("(min-width: 992px)");
    const lang = useCurrentLanguage();

    const handleNext = () => {
      const nextValue = Math.min(current + slidesToScroll, slidesAmount - slidesPerPage);
      setCurrent(nextValue);
      onChange(nextValue);
    };

    const handlePrev = () => {
      const prevValue = Math.max(current - slidesToScroll, 0);
      setCurrent(prevValue);
      onChange(prevValue);
    };

    return (
      <ProductsSlider
        className={`${arrows && !disableArrowsAndScrolling ? "ProductsSlider--withArrows" : ""} ${
          arrowsMini ? "ProductsSlider--arrowsMini" : ""
        }`}
        additional={
          <>
            {arrowsEnabled && arrowsMini && isDesktop ? (
              <div className="ProductsSlider__arrow_block">
                {title && <div>{title}</div>}
                <div>
                  <Button
                    className="ProductsSlider__arrow--left"
                    disabled={current === 0}
                    icon={<Icon name="chevron/left" width={arrowSize} height={arrowSize} />}
                    aria-label={lang === "ru" ? "Предыдущий слайд" : "Previous slide"}
                    onClick={handlePrev}
                  />
                  <Button
                    className="ProductsSlider__arrow--right"
                    disabled={current >= slidesAmount - slidesPerPage}
                    icon={<Icon name="chevron/right" width={arrowSize} height={arrowSize} />}
                    aria-label={lang === "ru" ? "Следующий слайд" : "Next slide"}
                    onClick={handleNext}
                  />
                </div>
              </div>
            ) : (
              arrowsEnabled && (
                <>
                  <Button
                    className={classNames(
                      "ProductsSlider__arrow",
                      "ProductsSlider__arrow--prev",
                      current === 0 && "ProductsSlider__arrow--disabled",
                    )}
                    theme="box"
                    icon={<Icon name="chevron/left" width={arrowSize} height={arrowSize} />}
                    disabled={current === 0}
                    aria-label={lang === "ru" ? "Предыдущий слайд" : "Previous slide"}
                    onClick={handlePrev}
                  />
                  <Button
                    className={classNames(
                      "ProductsSlider__arrow",
                      "ProductsSlider__arrow--next",
                      current >= slidesAmount - slidesPerPage && "ProductsSlider__arrow--disabled",
                    )}
                    theme="box"
                    icon={<Icon name="chevron/right" width={arrowSize} height={arrowSize} />}
                    disabled={current >= slidesAmount - slidesPerPage}
                    aria-label={lang === "ru" ? "Следующий слайд" : "Next slide"}
                    onClick={handleNext}
                  />
                </>
              )
            )}
            {!arrowsEnabled && isDesktop && title && <div>{title}</div>}
            {additional}
          </>
        }
        slidesPerPage={slidesPerPage}
        slidesToScroll={slidesToScroll}
        {...props}
        value={current}
        size={size}
        draggable={!disableArrowsAndScrolling && false}
        onChange={value => {
          setCurrent(value);
          onChange(value);
        }}
      >
        {children}
      </ProductsSlider>
    );
  };
