import { t } from "@lingui/macro";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import * as types from "../../../stores/types";
import Button from "../../Button";
import getFilterButtonsProps from "../getFilterButtonsProps";
import PopupOrDropdownAdapter from "../../PopupOrDropdownAdapter";
import { availableTourLangs } from "../../../functions/urlLanguage";
import { updateFilters } from "../../../stores/filter/reducers";
import Checkbox from "../../Checkbox";
import ChevronIcon from "../../ChevronIcon";

import "./LanguageFilter.css";

function LanguageFilter({ isMobile, i18n, dispatch, onApply = () => {} }) {
  const filtersState = useSelector(({ filters }) => filters);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const prefetchedCount = useSelector(({ products }) => products.prefetched.count);

  const sortedLanguages = [...availableTourLangs].sort((a, b) => {
    const aIsActive = filtersState.lang.includes(a.code);
    const bIsActive = filtersState.lang.includes(b.code);

    if (aIsActive && !bIsActive) {
      return -1;
    }
    if (bIsActive && !aIsActive) {
      return 1;
    }
    return 0;
  });

  const handleLanguageChange = code => {
    const currentLangs = filtersState.lang || [];
    const newLangs = currentLangs.includes(code)
      ? currentLangs.filter(lang => lang !== code)
      : [...currentLangs, code];
    updateFilters(dispatch, { lang: newLangs });
  };

  const applyFilters = () => {
    onApply();
  };

  const shouldShowSelection = filtersState.lang?.length > 0;

  const getSelectedLanguagesText = () => {
    if (!filtersState.lang?.length) return i18n._(t`Language`);

    if (filtersState.lang.length === 1) {
      const selectedLang = availableTourLangs.find(lang => lang.code === filtersState.lang[0]);
      return i18n._(selectedLang.t);
    }

    return i18n._(t`Languages: ${filtersState.lang.length}`);
  };

  const popupButtons = useMemo(
    () =>
      getFilterButtonsProps({
        onReset: () => {
          dispatch({ type: types.RESET_GENERAL_FILTERS });
        },
        prefetchedCount,
        i18n,
      }),
    [dispatch, i18n, prefetchedCount],
  );

  return (
    <PopupOrDropdownAdapter
      isMobile={isMobile}
      title={i18n._(t`Language`)}
      triggerElement={
        <Button
          theme={shouldShowSelection ? "black" : "default"}
          icon={!isMobile && <ChevronIcon isOpen={isPopupOpen} />}
          className="PriceAndDate__button"
          size="M"
        >
          {getSelectedLanguagesText()}
        </Button>
      }
      {...popupButtons}
      ContentComponent={() => (
        <div className="LanguageFilter__content">
          {sortedLanguages.map(({ t: nameLang, code }) => (
            <Checkbox
              key={code}
              checked={filtersState.lang?.includes(code)}
              onChange={() => handleLanguageChange(code)}
            >
              <span className="LanguageFilter__item">{i18n._(nameLang)}</span>
            </Checkbox>
          ))}
        </div>
      )}
      onOpened={() => setIsPopupOpen(true)}
      onClosed={() => {
        setIsPopupOpen(false);
        applyFilters();
      }}
      onStateChange={setIsPopupOpen}
    />
  );
}

export default LanguageFilter;
