import React from "react";
import uuid from "react-uuid";
import { format } from "date-fns";
import { Trans } from "@lingui/macro";
import classNames from "../../../functions/classNames";
import { REVIEW_TEXT_WORDS_LIMIT } from "../../../constants";
import reverseUrl from "../../../functions/reverseUrl";
import { getFnsLocale } from "../../../functions/dateTimeUtils";
import { Icon } from "../../Icon";
import Link from "../../Link";
import Button from "../../Button";
import TextExpand from "../../TextExpand";
import Rating from "../../Rating";
import Image from "../../Image";
import "./Review.css";

const AVATAR_SIZE = "32";
/**
 * Review form
 * @param {Object} $
 * @param {String} $.className - additional CSS class
 * @param {Object} $.product - info about product related to this review
 * @param {String} $.avatar - user avatar URL
 * @param {String} $.name - user name
 * @param {String} $.date - date of review posting in format `yyyy-MM-dd HH:mm:ss`
 * @param {String} $.text - review text
 * @param {Array[Object]} $.responses - array of descriptions of responses to the review (from server)
 * @param {Number} $.textLength - maximum amount of words in shrinked review text (default is `REVIEW_TEXT_WORDS_LIMIT`)
 * @param {Number?} $.lineHeight - passed to the `TextExpand` for calculation height bu amount of lines (default is `26` if `textLength` also set)
 * @param {Number?} $.linesAmount - will be passed to `TextExpand` instead of `textLength` if specified
 * @param {Number?} $.rating - amount of rating stars
 * @param {Function} $.onResponseRequested - "Show response" button was clicked
 * @param {Boolean} $.showLink - show link to the related product
 * @param {Boolean} $.shrinkResponses - hide responses from `responses` array under button (default is `true`)
 */
export default function Review({
  lang,
  id,
  rootRef,
  className,
  theme,
  product = {},
  avatar,
  name,
  text,
  textLength = REVIEW_TEXT_WORDS_LIMIT,
  linesAmount,
  lineHeight,
  rating,
  link,
  titleLink,
  date,
  showLink,
  responses = [],
  shrinkResponses = true,
  onResponseRequested = () => {},
}) {
  const [dateTime] = (date ?? "").split(" ");
  const dateText = date
    ? format(new Date(dateTime), "LLLL yyyy", { locale: getFnsLocale(lang) })
    : "";
  const {
    id: productId,
    slug: productSlug,
    title: productTitle,
    city: { id: cityId, slug: citySlug } = {},
    locale,
  } = product;
  const productLink =
    link ||
    (showLink && productId && productSlug && cityId && citySlug
      ? reverseUrl("product", {
          lang: locale || lang,
          productId,
          productSlug,
          cityId,
          citySlug,
        })
      : null);

  const randomUserId = Math.floor(Math.random() * 1000);

  return (
    <div ref={rootRef} className={classNames("Review", theme && `Review--${theme}`, className)}>
      <div className="Review__head">
        {avatar ? (
          <div className="Review__avatar">
            <Image
              width={AVATAR_SIZE}
              src={avatar}
              loading="lazy"
              className="Review__avatar-image"
            />
          </div>
        ) : (
          <Icon name="logo/gray" width={AVATAR_SIZE} height={AVATAR_SIZE} />
        )}
        <div className="Review__info">
          <div className="Review__title">
            {name || <Trans>User{randomUserId}</Trans>}
            {dateText && <div className="Review__date">{dateText}</div>}
          </div>
        </div>
        {!!rating && (
          <Rating
            className="Review__rating"
            grade={rating}
            gradeType="short"
            size="medium"
            starsType="large"
          />
        )}
      </div>
      {productLink && (titleLink || productTitle) ? (
        <Link external target="_blank" className="Review__link" theme="simple" to={productLink}>
          <span className="Review__link-icon">
            <Icon name="flag" />
          </span>
          {productTitle || titleLink}
        </Link>
      ) : null}
      <div className="Review__text">
        <TextExpand
          fullText={text || ""} // Подставляем пустую строку, если text === null
          {...(linesAmount
            ? { linesAmount, lineHeight: lineHeight || 23.5 }
            : { limit: textLength })}
        />
      </div>

      {responses.length ? (
        <div className="Review__responses">
          {!shrinkResponses ? (
            responses.map(({ user, text: responseText }) => (
              <div key={uuid()} className="Review__response">
                <Review
                  lang={lang}
                  name={<Trans>Author&apos;s answer ({user.name})</Trans>}
                  avatar={user.avatar}
                  text={responseText}
                  theme="lite"
                />
              </div>
            ))
          ) : (
            <Button
              className="Review__response-show"
              theme="simple"
              iconPosition="before"
              icon={<Icon name="newline" width="14" height="14" />}
              onClick={() => onResponseRequested(id)}
            >
              <Trans>Show author&apos;s answer</Trans>
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
}
