import { Trans } from "@lingui/macro";
import React from "react";
import Link from "../Link";
import reverseUrl from "../../functions/reverseUrl";
import "./SitemapBlock.css";

export const SitemapBlock = ({ city, lang, cityName }) => {
  return (
    <div className="SitemapBlock">
      <div className="SitemapBlock__title">
        <Trans>Want to discover all there is to do in {cityName}</Trans>
      </div>
      {!!city?.slug && (
        <Link
          to={reverseUrl("destinations-city", {
            lang,
            cityId: city.id,
            citySlug: city.slug,
          })}
          target="_blank"
          className="SitemapBlock__link"
        >
          <Trans>Click here for a full list</Trans>
        </Link>
      )}
    </div>
  );
};
