import React, { useEffect, useMemo, useState } from "react";
import { Trans } from "@lingui/macro";
import { useDispatch } from "react-redux";
import { FETCH_PRODUCTS } from "../../stores/types";
import { getProductLink } from "../../functions/getProductLink";
import classNames from "../../functions/classNames";
import reverseUrl from "../../functions/reverseUrl";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import ProductCard from "../ProductCard";
import Spinner from "../Spinner";
import Button from "../Button";
import Placeholder from "../Placeholder";
import sendEvent from "../../functions/analytics";
import "./ProductsGrid.css";
import { Icon } from "../Icon";

export const getHref = (selectParams, lang) => {
  let href = reverseUrl("main", { lang });
  const { cityId, categoryId, attractionId, attractionSlug } = selectParams;

  if (categoryId) {
    href = reverseUrl("category", {
      lang,
      cityId,
      citySlug: "city",
      categoryId,
      categorySlug: "category",
    });
  } else if (cityId) {
    href = reverseUrl("city", { lang, cityId, citySlug: "city" });
  } else if (attractionId && attractionSlug) {
    href = reverseUrl("attraction-simple", {
      lang,
      attractionId,
      attractionSlug,
    });
  }
  return href;
};

/**
 * Product Grid Component
 * @component
 * @param {Object[]} products - Array of received products
 * @param {Boolean} isLinkButton - if a "Show more" should be a link or normal button
 * @param {Boolean} showMoreButton - Whether to show a "Show more" button
 * @param {String} next - Index of the next set of products
 * @param {Boolean} loading - Loading state
 * @param {Object} selectParams - Parameters for fetching products and "show more link":
 *                                `lang`,  `cityId`, `categoryId`, `attractionId`, `attractionSlug`, etc.
 *                                If `lang` is equal to `null` products of all languages will be fetched.
 * @param {Boolean} withFavorite - Whether to include a favorite feature
 * @param {String} className - Additional CSS class name
 * @param {String} urlParams - Additional URL parameters for Product card link
 * @param {String} listId - ID for the list of products
 * @param {Function} onClicked - Callback for when a product is clicked
 * @param {Boolean} disableDefaultSendEvent - Whether to disable default analytics events
 * @param {String} rel - `rel` attribute for the link
 * @param {Number} count - Total number of products
 * @param {Number} perPage - Number of products per page
 * @param {String} actionType - Redux action type for fetching products
 * @returns {JSX.Element}
 */
export default ({
  products = [],
  isLinkButton,
  showMoreButton = true,
  next,
  loading,
  selectParams,
  withFavorite,
  className,
  urlParams = "",
  listId,
  onClicked,
  disableDefaultSendEvent,
  rel,
  count: productsCount,
  perPage,
  title,
  limitDisplay,
  children,
  addPlaceholder,
  horizontalSlider,
  highlightProductId,
}) => {
  const lang = useCurrentLanguage();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 3000;
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, []);

  const linkButtonProps = useMemo(
    () =>
      isLinkButton
        ? {
            external: true,
            to: getHref(selectParams, lang),
            theme: "main",
            target: "_blank",
            ...(rel ? { rel } : {}),
          }
        : {},
    [isLinkButton, selectParams, lang, rel],
  );

  const placeholderCard = addPlaceholder && (
    <div className="ProductsGrid__placeholder">
      <a
        href={getHref(selectParams, lang)}
        className="ProductsGrid__placeholder-card"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="ProductsGrid__placeholder-content">
          <Trans>See all</Trans>
          <Icon name="chevron/right" />
        </div>
      </a>
    </div>
  );

  if (loading) {
    return (
      <div className="ProductsGrid__loader">
        <Spinner size="large">
          <Trans>Tours are loading</Trans>
        </Spinner>
      </div>
    );
  } else if (!isLoading && !products.length && !loading) {
    return <Placeholder subtitle={<Trans>No tours presented here yet</Trans>} />;
  }

  return (
    <div className={classNames("ProductsGrid__wrapper", className)}>
      {title && <h2 className="ProductsGrid__title">{title}</h2>}
      <div
        className={classNames("ProductsGrid", horizontalSlider ? "ProductsGrid--horizontal" : "")}
      >
        {(limitDisplay && perPage ? products.slice(0, perPage) : products).map((item, i) => (
          <ProductCard
            key={item.id}
            rel={rel}
            position={i}
            withFavorite={withFavorite}
            link={getProductLink(lang, item) + urlParams}
            product={item}
            listId={listId}
            disableDefaultSendEvent={disableDefaultSendEvent}
            highlight={item.id === Number(highlightProductId)}
            onClicked={onClicked}
          />
        ))}
        {placeholderCard}
      </div>
      {children}
      {next && showMoreButton && products.length < productsCount && (
        <Button
          theme="card"
          {...linkButtonProps}
          size="L"
          className="ProductsGrid__button"
          onClick={() => {
            if (!isLinkButton) {
              dispatch({
                type: FETCH_PRODUCTS,
                page: next,
                append: true,
                perPage,
                ...selectParams,
                // if selectParams.lang is equal to `null` products of all languages will be fetched
                lang: selectParams.lang !== undefined ? selectParams.lang : lang,
              });
            }
            // TODO: get sendEvent from props
            sendEvent("track", "Widget Click Show More");
          }}
        >
          {loading ? (
            <>
              <Spinner /> <Trans>Loading</Trans>
            </>
          ) : (
            <Trans>Show more</Trans>
          )}
        </Button>
      )}
    </div>
  );
};
