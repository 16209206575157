import { useState, useEffect, useMemo } from "react";

export const useScrollToProductGrid = highlightProductId => {
  useEffect(() => {
    if (highlightProductId) {
      const productsGridElement = document.querySelector(".ProductsGrid");
      if (productsGridElement) {
        const elementPosition =
          productsGridElement.getBoundingClientRect().top + window.pageYOffset;

        window.scrollTo({
          top: elementPosition - 250,
          behavior: "smooth",
        });
      }
    }
  }, [highlightProductId]);
};

const useHighlightedProduct = (products, sortOrder) => {
  const [highlightProductId, setHighlightProductId] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search);
      setHighlightProductId(searchParams.get("highlight"));
    }
  }, []);

  const sortedProducts = useMemo(() => {
    let productList = products?.products ?? [];
    if (!productList.length) return [];

    const id = highlightProductId ? Number(highlightProductId) : null;

    let sorted = [...productList].sort((a, b) => {
      if (sortOrder === "price") return a.price - b.price;
      if (sortOrder === "-price") return b.price - a.price;
      if (sortOrder === "rating") return b.rating - a.rating;
      return 0;
    });

    if (!id) return sorted;

    const index = sorted.findIndex(p => p.id === id);

    if (index !== -1 && sortOrder !== "price" && sortOrder !== "-price" && sortOrder !== "rating") {
      const [highlighted] = sorted.splice(index, 1);
      sorted.unshift(highlighted);
    }

    return sorted;
  }, [products, highlightProductId, sortOrder]);

  return { sortedProducts, highlightProductId };
};

export default useHighlightedProduct;
