import reverseUrl from "./reverseUrl";
import collectObjectParams from "./objects/collectObjectParams";

/**
 * Generates a list of objects with props to be used as link `href` and `text`
 * @param {object} $
 * @param {string} $.name - name of route from ./routes
 * @param {object[]} $.destinations - array of destinations from server
 * @param {object} $.destinationsParams - params to be collected from `destinations`
 * @param {object?} $.additionalParams - parans not from `destinations`
 * @param {string} $.lang - page language code
 */
export default function getDestinationLinksList({
  name,
  destinations = [],
  destinationsParams = {},
  additionalParams,
  lang,
}) {
  const entries = Object.entries(destinationsParams);
  const safeDestinations = Array.isArray(destinations) ? destinations : [];

  return safeDestinations.map(destination => {
    const text = destination.title || destination.name;

    return {
      link: reverseUrl(name, {
        lang,
        ...collectObjectParams(destination, entries),
        ...additionalParams,
      }),
      text,
    };
  });
}
