import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import { useMediaQuery } from "beautiful-react-hooks";
import classNames from "../../functions/classNames";
import { scrollEnd, scrollToNear } from "../../functions/scroll";
import { isSSR } from "../NoSSR";
import "@brainhubeu/react-carousel/lib/style.css";
import "./ProductsSlider.css";

/**
 * Slidable carousel
 * @param {Object} $
 * @param {String} $.className - external CSS class
 * @param {String} $.theme - theme of the component
 * @param {String} $.size - additional sizes of the component (`"small"`)
 * @param {Array[React::Element]} $.children - slides for carousel
 * @param {React::Ref} $.innerRef - reference to original carousel component
 * @param {Boolean} $.flatOnMobile - just scrollable block with no carousel on mobile
 * @param {Boolean} $.chainedScroll - enable chained scroll on mobile devices in flat mode
 */
export default ({
  className,
  theme,
  size,
  additional,
  children,
  innerRef,
  containerRef,
  flatOnMobile,
  chainedScroll,
  flatOnPad = true,
  slidesToScroll = 1,
  ...props
}) => {
  const isPad = !isSSR && useMediaQuery("(max-width: 991px)");
  const isMobile = !isSSR && useMediaQuery("(max-width: 767px)");
  const contentScrollEnd = scrollEnd(150, scrollToNear);

  if ((isPad && flatOnMobile && flatOnPad) || (isMobile && flatOnMobile)) {
    return (
      <span
        ref={containerRef}
        className={classNames(
          "ProductsSlider",
          "ProductsSlider--flat",
          theme && `ProductsSlider--${theme}`,
          size && `ProductsSlider--${size}`,
          className,
        )}
        onScroll={isMobile && chainedScroll ? contentScrollEnd : () => {}}
      >
        {additional}
        {children}
      </span>
    );
  }

  return (
    <div
      ref={containerRef}
      className={classNames(
        "ProductsSlider",
        theme && `ProductsSlider--${theme}`,
        size && `ProductsSlider--${size}`,
        className,
      )}
    >
      {additional}
      <Carousel
        ref={innerRef}
        keepDirectionWhenDragging
        minDraggableOffset={5}
        slidesToScroll={slidesToScroll}
        {...props}
      >
        {children}
      </Carousel>
    </div>
  );
};
