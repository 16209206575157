/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback } from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { Trans } from "@lingui/macro";
import { sendListViewedEvent } from "../../functions/analytics";
import ScrollHook from "../ScrollHook";
import withArrows from "../ProductsSlider/withArrows";
import ProductsSlider from "../ProductsSlider";
import { DEFAULT_LANG } from "../../constants";
import "./BestActivities.css";
import ProductCard from "../ProductCard";
import { getProductLink } from "../../functions/getProductLink";

const ProductCarousel = withArrows(ProductsSlider);

export const BestActivities = ({ lang = DEFAULT_LANG, products = [] }) => {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const slidesPerPage = isDesktop ? 4 : 3;
  const slidesToScroll = isDesktop ? 4 : 3;
  const productListId = "viewed";

  /**
   * Product list became visible in viewport
   */
  const onProductListShown = useCallback(() => {
    sendListViewedEvent(productListId, products, lang);
  }, [productListId, products, lang]);

  return (
    <div className="Main__recently BestActivities">
      <ScrollHook once="shown" showOn=".Main__recently" onChanged={onProductListShown} />
      <div className="Wrapper__overload">
        {!isDesktop ? (
          <h2 className="BestActivities__title">
            <Trans>Best sellers activities</Trans>
          </h2>
        ) : null}
        <ProductCarousel
          arrowsMini
          flatOnMobile
          chainedScroll
          slidesPerPage={slidesPerPage}
          slidesToScroll={slidesToScroll}
          infinite={false}
          animationSpeed={500}
          title={
            <h2 className="BestActivities__title">
              <Trans>Best sellers activities</Trans>
            </h2>
          }
        >
          {products.products.slice(0, 12).map((item, i) => (
            <div key={item.id}>
              <ProductCard
                key={item.id}
                position={i}
                link={getProductLink(lang, item)}
                product={item}
              />
            </div>
          ))}
        </ProductCarousel>
      </div>
    </div>
  );
};
