/* eslint-disable no-shadow */
/* eslint-disable import/no-cycle */
import React, { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "beautiful-react-hooks";
import { connect, useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";
import get from "lodash/get";
import { JsonLd } from "react-schemaorg";
import LazyHydrate from "react-lazy-hydration";
import { sortBy } from "lodash";
import * as types from "../../stores/types";
import routes from "../../routes";
import { isMobileUserAgent, getUserAgent } from "../../functions/userAgent";
import { Api } from "../../functions/fetchFromApi";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import detectRoute from "../../functions/route/detectRoute";
import { fetchDefaultCurrency } from "../../functions/currency";
import toQueryString from "../../functions/toQueryString";
import { sendListViewedEvent } from "../../functions/analytics";
import { fetchLinkings, fetchOverview } from "../../functions/fetchData";
import reverseUrl from "../../functions/reverseUrl";
import replaceImgTagsInHtml from "../../functions/optimizeImagesInHtml";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import Root from "../../components/_Root";
import StackedAccordions from "../../components/StackedAccordions";
import TextExpand from "../../components/TextExpand";
import { isSSR } from "../../components/NoSSR";
import ScrollHook from "../../components/ScrollHook";
import { CanonicalAuto } from "../../components/Canonical";
import Hero from "../../components/Hero";
import { CustomerReviews } from "../../components/Reviews";
import { getProductLink } from "../../functions/getProductLink";
import getDomainZone from "../../functions/url/getDomainZone";
import { AlternateAuto } from "../../components/Alternate";
import ProductsGridAdapter from "../../components/ProductsGrid/ProductsGridAdapter";
import LinkingsGroup, { LinkingsGroups } from "../../components/Linking";
import ProductsFilter from "../../components/ProductsFilter";
import ButtonlikeLinksBlock from "../../components/ButtonlikeLinksBlock";
import { usePageViewTracking } from "../../functions/usePageViewTracking";
import AudioGuideSection from "../../components/AudioGuideSection/Index";
import CityLinkSection from "../../components/CityLinkSection";
import EmailSubscriptionSection from "../../components/EmailSubscriptionSection";
import MostRecommended from "../../components/MostRecommended";
import { SitemapBlock } from "../../components/SitemapBlock";
import { Categories } from "../../components/Categories";
import getDestinationLinksList from "../../functions/getDestinationLinksList";
import { fetchCityAttractionsWithTickets } from "../../functions/fetchCityAttractionsWithTickets";

import "./Attraction.css";

const Attraction = ({
  city = {},
  products = {},
  attraction,
  destinations,
  linkings,
  seoHtml = "",
  mostRecommended,
  overallCount,
  path = "",
  reviews = {},
  attractionId,
  cityAttractionsWithoutTickets,
  recommendedCities,
  productsPerPage,
  currency,
  interests,
  filters,
  userId,
}) => {
  const lang = useCurrentLanguage();
  const DNSZone = getDomainZone(lang);
  const cityId = city.id;
  const currentPath = isSSR ? path : window.location.pathname;
  const { pathname } = useLocation();
  const route = detectRoute(
    routes.filter(r => /attraction-tickets/i.test(r.name)),
    pathname,
  );
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isMobileView = !isSSR && isMobile;
  const dispatch = useDispatch();
  usePageViewTracking("attraction", { userId, attractionId });

  const memoizedData = useMemo(
    () => ({
      city: { ...city },
      attraction: { ...attraction },
      cityName: inclineIfNeeded(city.name, "in", lang),
      productListId: `attraction_${attraction?.slug}_${attraction?.id}`,
      citiesLinks: sortBy(
        getDestinationLinksList({
          name: "city",
          destinations: recommendedCities,
          destinationsParams: {
            cityId: "id",
            citySlug: "slug",
          },
          lang,
        }),
        ["text"],
      ),
    }),
    [city, attraction, lang, recommendedCities],
  );

  const { cityName, productListId, citiesLinks } = memoizedData;

  const onProductListShown = useCallback(() => {
    sendListViewedEvent(productListId, products.products, lang);
  }, [productListId, products.products, lang]);

  const findPriceByCurrency = useCallback((prices, currency) => {
    return prices.find(price => price.currencyCode === currency);
  }, []);

  const lowestPrice = useMemo(
    () => findPriceByCurrency(memoizedData.city.minPrice, currency),
    [memoizedData.city.minPrice, currency, findPriceByCurrency],
  );

  const defaultProductsGridProps = useMemo(
    () => ({
      listId: productListId,
      perPage: productsPerPage,
      isMobile: isMobileView,
      lang,
    }),
    [productListId, productsPerPage, isMobileView, lang],
  );

  if (isSSR) return null;

  return (
    <Root stickyHeader searchInHeader destinations={destinations}>
      {route && <AlternateAuto route={route} />}
      {route && <CanonicalAuto route={route} />}

      <Hero
        city={memoizedData.city}
        lowestPrice={lowestPrice}
        initialMetaTitle={memoizedData.city.meta_title}
        initialMetaDescription={memoizedData.city.meta_description}
        attraction={memoizedData.attraction}
        current="attraction"
        theme="no-background"
      />

      <div className="Wrapper attraction-page-wrapper">
        <LazyHydrate whenVisible>
          <ScrollHook once="shown" showOn=".City__products" onChanged={onProductListShown} />
          <AudioGuideSection />
          <div>
            <div className="attraction-page-wrapper__products-filter_title">
              <Trans>All self-guided activities</Trans>
            </div>
            <ProductsFilter
              isMobile={isMobile}
              isDesktop={!isMobile}
              dispatch={dispatch}
              attraction={attraction}
              lang={lang}
            />

            <ProductsGridAdapter
              {...defaultProductsGridProps}
              products={products.prefetched.products}
              queryData={{
                loading: products.loading,
                current: products.current,
                next: products.next,
                count: products.count,
                pages: products.pages,
              }}
              selectParams={{
                cityId,
                currency,
                attractionId,
                ...filters,
                lang: filters?.lang?.length ? filters.lang : lang,
                separateTo: "mainProducts",
              }}
              showMorePaginate={!isMobile}
              limitDisplay={false}
            />
          </div>
        </LazyHydrate>

        <LazyHydrate whenVisible>
          {memoizedData.attraction?.nearbyAttractions?.length > 0 && (
            <Categories
              products={memoizedData.attraction.nearbyAttractions}
              lang={lang}
              city={memoizedData.city}
              type="attraction"
              title={<Trans>Top sights near {memoizedData.attraction?.name}</Trans>}
            />
          )}

          <CityLinkSection city={memoizedData.city} lang={lang} />
          <EmailSubscriptionSection city={memoizedData.city} attractionId={attractionId} />

          <MostRecommended
            categoryTitle={memoizedData.attraction?.name || null}
            city={memoizedData.city}
            mostRecommended={mostRecommended}
            lang={lang}
          />

          {cityId && (
            <CustomerReviews
              showOverallRating
              overallCount={overallCount}
              withPopup={isMobileView}
              lang={lang}
              isMobile={isMobileView}
              queryParams={{ attractionId }}
              title={
                <Trans>What people say about {memoizedData.attraction?.name || cityName}</Trans>
              }
            />
          )}

          <LinkingsGroups
            interests={interests}
            dispatch={dispatch}
            cityAttractionsWithoutTickets={cityAttractionsWithoutTickets?.results || []}
            fetchCityAttractionsWithTickets={() =>
              fetchCityAttractionsWithTickets({
                cityId,
                countryId: memoizedData.city.country?.id,
                lang,
              })
            }
            city={memoizedData.city}
            cityId={cityId}
            cityName={cityName}
            lang={lang}
          />

          {!!recommendedCities?.length && memoizedData.city?.country?.name && (
            <ButtonlikeLinksBlock
              slideShow={isMobile}
              titleText={<Trans>Cities in {memoizedData.city.country.name}</Trans>}
              links={citiesLinks}
            />
          )}

          <LinkingsGroup
            onlyPopularBlock
            linkings={linkings}
            city={memoizedData.city}
            lang={lang}
          />

          {seoHtml && (
            <TextExpand
              html
              allowOriginalFormat
              shortText={seoHtml
                .slice(0, 300)
                .replace(/<\/?(tr|td|ul|li|p|b|div|span|a)[^>]*(>|$)/gi, "")}
              fullText={seoHtml}
            />
          )}

          <StackedAccordions data={memoizedData.attraction?.abouts} TitleTag="h2">
            <Trans>About {memoizedData.attraction?.name}</Trans>
          </StackedAccordions>

          <StackedAccordions data={memoizedData.attraction?.faqs}>
            <Trans>FAQ about {memoizedData.attraction?.name}</Trans>
          </StackedAccordions>

          <SitemapBlock city={memoizedData.city} lang={lang} cityName={cityName} />
        </LazyHydrate>

        <JsonLd
          item={{
            "@context": "https://schema.org",
            "@type": "Product",
            url: `https://wegotrip.${DNSZone}${currentPath}`,
            sku: memoizedData.attraction?.id,
            name: memoizedData.attraction?.name,
            description: memoizedData.attraction?.meta_description,
            brand: { "@type": "Brand", name: "WeGoTrip" },
            image: memoizedData.attraction?.preview,
            ...(reviews.count
              ? {
                  aggregateRating: {
                    "@type": "AggregateRating",
                    ratingValue: reviews.averageRating,
                    reviewCount: reviews.count,
                  },
                }
              : {}),
            ...(reviews.count
              ? {
                  review: reviews.reviews.map(review => ({
                    "@type": "Review",
                    author: { "@type": "Person", name: review.name },
                    datePublished: review.date,
                    description: review.text,
                    reviewRating: {
                      "@type": "Rating",
                      bestRating: "5",
                      ratingValue: review.rating,
                      worstRating: "1",
                    },
                  })),
                }
              : {}),
            ...(products.count
              ? {
                  offers: products.products.map(product => ({
                    "@type": "Offer",
                    price: product.price,
                    priceCurrency: product.currencyCode,
                    url: `https://wegotrip.${DNSZone}${getProductLink(lang, product)}`,
                    availability: "https://schema.org/InStock",
                  })),
                }
              : {}),
          }}
        />

        {memoizedData.attraction?.faqs && (
          <JsonLd
            item={{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              mainEntity: memoizedData.attraction.faqs.map(question => ({
                "@type": "Question",
                name: question.title,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: question.body,
                },
              })),
            }}
          />
        )}
      </div>
    </Root>
  );
};

Attraction.getInitialProps = withRedirectToKnownLang(async ({ req, match, store }) => {
  try {
    const cookies = get(req, "headers.cookie");
    const mobile = isMobileUserAgent(getUserAgent(req));
    store.dispatch({ type: types.FETCH_USER, cookies });

    const currency = await fetchDefaultCurrency(req);
    store.dispatch({ type: types.SET_DEFAULT_CURRENCY, defaultCurrency: currency });

    const { attractionId } = match.params;
    const lang = getCurrentLanguage(match.params.lang);
    let { cityId } = match.params;
    let seoHtml = "";

    const productsPerPage = mobile ? 4 : 20;

    store.dispatch({
      type: types.FETCH_PRODUCTS,
      currency,
      lang,
      perPage: productsPerPage,
      cityId,
      attractionId,
      separateTo: "mainProducts",
    });

    store.dispatch({ type: types.FETCH_LANGUAGES });

    const overview = await fetchOverview({ lang }, req);
    store.dispatch({ type: types.SET_OVERVIEW, data: overview });

    const { data: attraction } = await Api.get(
      `/api/v2/attractions/${attractionId}/?${toQueryString({
        lang,
        expand: "meta_title,meta_description,schedule,abouts,faqs",
        preorder: true,
      })}`,
      { lang },
    );

    // Redirect from the long attraction route to the short one
    if (cityId) {
      return {
        redirectTo: reverseUrl("attraction-simple", {
          attractionId,
          attractionSlug: attraction.slug,
          lang,
        }),
      };
    }

    if (!cityId && attraction.city) {
      cityId = attraction.city.id;
    }

    seoHtml = attraction.description;

    const city = (
      await Api.get(
        `/api/v2/cities/${cityId}/?${toQueryString({
          lang,
          expand: "h1,meta_title,meta_description,description",
          preorder: true,
        })}`,
        { lang },
      )
    ).data;

    let cityAttractionsWithoutTickets;
    if (city.country?.id) {
      cityAttractionsWithoutTickets = await Api.get(
        `/api/v3/attractions/?${toQueryString({
          lang,
          city_id: cityId,
          country_id: city.country.id,
          include_preorder: false,
          per_page: 20,
          sort_by: "popularity",
        })}&with_tickets=false`,
        { lang },
      );
    }

    store.dispatch({
      type: types.FETCH_CATEGORIES,
      payload: { cityId, lang },
    });

    store.dispatch({
      type: types.FETCH_INTERESTS,
      payload: { cityId, lang },
    });

    store.dispatch({
      type: types.FETCH_MOST_RECOMMENDED,
      payload: { city: cityId, attraction: attractionId, lang },
    });

    const {
      reviews: { count: reviewsCount },
    } = store.getState();
    if (!reviewsCount) {
      store.dispatch({
        type: types.FETCH_REVIEWS,
        lang,
        attractionId,
        per_page: mobile ? 3 : 6,
      });
    }

    store.dispatch({
      type: types.FETCH_OVERALL_RATING,
      payload: { id: attractionId, type: "attraction", lang },
    });

    store.dispatch({
      type: types.FETCH_CITIES,
      payload: {
        countryId: city.country.id,
        lang,
      },
    });

    const linkings = await fetchLinkings({ lang, id: cityId });

    return {
      attractionId,
      destinations: overview.topCities,
      city,
      cityAttractionsWithoutTickets,
      productsPerPage,
      currency,
      attraction,
      mobile,
      linkings,
      seoHtml: replaceImgTagsInHtml(seoHtml),
      path: req?.originalUrl,
    };
  } catch (error) {
    // TODO: serve 500 for not 404 errors
    return { statusCode: 404 };
  }
});

const mapStateToProps = ({ products, user, cities, reviews, filters }) => ({
  products,
  cityProducts: products.cityProducts,
  userId: user.user.id,
  mostRecommended: products.mostRecommendedProducts,
  recommendedCities: cities.cities,
  categories: cities.categories,
  interests: cities.interests,
  reviews,
  overallCount: reviews.overallCount,
  filters,
});

export default connect(mapStateToProps)(Attraction);
