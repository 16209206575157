/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
import React, { useMemo } from "react";
import { t, Trans } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import getCategoryMetaDict from "../../functions/categories/getCategoryMetaDict";
import classNames from "../../functions/classNames";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import categoriesMetaRu from "../../constants/categories/categoriesMetaRu";
import categoriesMetaEn from "../../constants/categories/categoriesMetaEn";
// eslint-disable-next-line import/no-cycle
import Breadcrumbs from "../Breadcrumbs";
import OpenHours from "../OpenHours";
import Image from "../Image";
import "./Hero.css";

const Hero = ({
  i18n,
  initialMetaTitle,
  initialMetaDescription,
  country,
  city,
  category,
  attraction,
  cover,
  current,
  className,
  withTickets,
  theme,
  lowestPrice,
}) => {
  // TODO: refactor, move all meta settings and title settings to props or incorporate all logic here
  const lang = useCurrentLanguage();
  const { categoryId } = useParams();
  const isCategoryActive = String(category?.id) === categoryId;
  const year = new Date().getFullYear();
  /**
   * Resolving meta tags for categories
   * Without fetching, stored in page bundle
   */
  let metaTitle = initialMetaTitle;
  let metaDescription = initialMetaDescription;
  let meta;
  if (category) {
    const metaDicts = { ru: categoriesMetaRu, en: categoriesMetaEn }[lang] || {};
    meta = getCategoryMetaDict(metaDicts, category.id);
    if (meta) {
      const metaDictWords = {
        city: city.name,
        category: category.title,
      };
      if (city.name === "Гайды для путешествий") {
        metaDictWords.cityDa = "Гайдам для путешествий";
      }
      metaDescription = meta.description(metaDictWords);
      metaTitle = meta.title(metaDictWords);
    }
  }

  // TODO: fallback
  const coverPath = cover || (attraction && attraction.cover) || (city && city.cover);

  let locationName;
  if (country) {
    locationName = country.name;
  } else if (city) {
    locationName = city.name;
  }

  function countWords(str) {
    if (!str) return 0;
    return str.trim().split(/\s+/).length;
  }

  function getInclinedAttractionName(attractionName, language) {
    if (!attractionName) return "";
    if (countWords(attractionName) === 1) {
      return inclineIfNeeded(attractionName, "in", language);
    } else {
      return attractionName;
    }
  }
  // TODO: count seoTitle from API
  let title;
  let description;
  let cityName;
  let attractionName;
  if (attraction && withTickets) {
    attractionName = attraction.name;
    title = i18n._(t`${attractionName} Tickets: Book Online`);
    description = i18n._(
      t`Buy tickets online to ${attractionName} in ${
        city.name ? inclineIfNeeded(city.name, "in", i18n.language) : ""
      } ☆ Prices from ${
        lowestPrice ? `${lowestPrice.priceValue} ${lowestPrice.currencyCode}` : "5 USD"
      } ☆ Book your tickets, download and listen to the audio guide. Join thousands happy customers who use self-guided tours in the App`,
    );
  } else if (attraction && !withTickets) {
    // attractionName = inclineIfNeeded(attraction.name, "in", i18n.language);
    attractionName = getInclinedAttractionName(attraction.name, i18n.language);

    title = i18n._(t`${attractionName} Audio Guides: Download and listen to online audio tours`);
    description = i18n._(
      t`The best audio guides for ${attractionName} from experts WeGoTrip ☆ Prices from ${
        lowestPrice ? `${lowestPrice.priceValue} ${lowestPrice.currencyCode}` : "5 USD"
      } ☆ Download and listen to online audio tours of ${attractionName}.`,
    );
  } else {
    cityName = inclineIfNeeded(locationName, "in", i18n.language);
    title =
      locationName === country?.name
        ? i18n._(t`Audio guides in ${cityName} - Download and listen to self-guided audio tours`)
        : locationName === city?.name && !category
        ? i18n._(
            t`The Best ${cityName} Things To Do in ${year}: Tours, Audio Guides, Attractions & Activities`,
          )
        : category
        ? i18n._(t`The Best ${category.title} in ${cityName} (Updated ${year})`)
        : i18n._(t`The Best Things To Do in ${cityName} (Updated ${year})`);
    description =
      locationName === country?.name
        ? i18n._(
            t`The Best ${cityName} Self-Guided Audio Tours in ${year}. Travel at your own rules with online audio guides to the sights and places of interest of ${cityName}`,
          )
        : cityName === city?.name && !category
        ? i18n._(
            t`The Best Things To Do in ${cityName}: audio tours, excursions and activities in ${year}. Find the top-rated trips in ${cityName} with entrance tickets and prices`,
          )
        : category
        ? i18n._(
            t`${cityName} Self-Guided ${category.title} in ${year}. Audio Guides with included entrance sights tickets to the best places of ${cityName}`,
          )
        : i18n._(
            t`The Best Things To Do in ${cityName}: audio tours, excursions and activities in ${year}. Find the top-rated trips in ${cityName} with entrance tickets and prices`,
          );
  }

  const renderH1 = () => {
    if (category && city && lang !== "ru") {
      // lang !== "ru" is used until translation is approved
      return `${isCategoryActive ? `${category.title} in ` : ""}${city.name}`;
    }
    if (withTickets) {
      if (attraction.h1_dupl && lang === "ru") return attraction.h1_dupl;
      return <Trans>Tickets to the {inclineIfNeeded(attraction.name, "in", i18n.language)}</Trans>;
    }
    if (attraction) {
      if (attraction.h1) return attraction.h1;
      return (
        <Trans>
          {inclineIfNeeded(attraction.name, "in", i18n.language)}: Self-Guided Audio Tours
        </Trans>
      );
    }
    if (lang === "ru" && category && meta && metaTitle) {
      const delimiter = [" — ", " - "].find(d => metaTitle.indexOf(d) > -1);
      return delimiter ? metaTitle.split(delimiter).slice(0, -1).join(delimiter) : metaTitle;
    }
    if (city) {
      if (lang === "ru" && city.h1) {
        return city.h1;
      } else if (lang !== "ru") {
        return <Trans>Things to do in {city.name} for self travelers</Trans>;
      }
    }

    return <Trans>{cityName} Audio Tours</Trans>;
  };

  const memoizedH1 = useMemo(
    () => renderH1(),
    [withTickets, attraction, category, city, lang, metaTitle],
  );

  return (
    <section className={classNames("Hero", theme && `Hero--${theme}`, className)}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        {/* <meta property="og:description" content={city.description} /> */}
        <meta property="og:image" content={cover} />
        <meta property="og:site_name" content="WeGoTrip" />
        <meta property="og:type" content="website" />
      </Helmet>
      {theme !== "no-background" && (
        <div className="Hero__background">
          <Image
            src={coverPath}
            imgProxyOptions={{ gravity: "sm", resizingType: "none" }}
            loading="lazy"
          />
        </div>
      )}
      <div className="Wrapper Hero__breadcrumbs">
        <Breadcrumbs
          theme={theme !== "no-background" ? "white" : ""}
          withTickets={withTickets}
          country={country || city.country}
          city={city}
          category={category}
          attraction={attraction}
          current={current}
        />
      </div>
      <section className="Hero__wrapper">
        <div className="Wrapper">
          <h1 className="h1 m-32 m-64-t Hero__title">{memoizedH1}</h1>
          {!country && (
            <span className="Hero__subtitle">
              <Trans>
                Enjoy self-guided audio tours with included attraction tickets in one app
              </Trans>
            </span>
          )}
          {attraction && <OpenHours product={attraction} />}
        </div>
      </section>
    </section>
  );
};

export default withI18n()(Hero);
