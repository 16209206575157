/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback } from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { Trans } from "@lingui/macro";
import { sendListViewedEvent } from "../../functions/analytics";
import classNames from "../../functions/classNames";
import ScrollHook from "../ScrollHook";
import withArrows from "../ProductsSlider/withArrows";
import ProductsSlider from "../ProductsSlider";
import { DEFAULT_LANG } from "../../constants";
import "./Categotries.css";
import reverseUrl from "../../functions/reverseUrl";

const ProductCarousel = withArrows(ProductsSlider);

function CategoryCard({ link, className, previewImage, title, rel }) {
  return (
    <a
      href={link}
      target="_blank"
      {...(rel ? { rel } : {})}
      className={classNames("CategoryCard", className)}
    >
      <img src={previewImage} alt={title} className="CategoryCard__image" />
      <span className="t-m CategoryCard__title">{title}</span>
    </a>
  );
}

export const Categories = ({
  lang = DEFAULT_LANG,
  products = [],
  city,
  type = "category",
  title,
}) => {
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const slidesPerPage = isDesktop ? 5 : 3;
  const slidesToScroll = isDesktop ? 5 : 3;
  const productListId = "viewed";

  const onProductListShown = useCallback(() => {
    sendListViewedEvent(productListId, products, lang);
  }, [productListId, products, lang]);

  const getCardProps = (product, i) => {
    if (type === "attraction") {
      return {
        title: product.name,
        link: reverseUrl("attraction-simple", {
          lang,
          attractionId: product.id,
          attractionSlug: product.slug,
        }),
        position: i,
        listId: productListId,
        previewImage: product.preview || "",
      };
    }

    return {
      title: product.category.title,
      link: reverseUrl("category", {
        lang,
        citySlug: city.slug,
        cityId: city.id,
        categoryId: product.category.id,
        categorySlug: product.category.slug,
      }),
      position: i,
      listId: productListId,
      previewImage: product.preview_image,
    };
  };

  return (
    <div className="Main__recently Categories">
      <ScrollHook once="shown" showOn=".Main__recently" onChanged={onProductListShown} />
      <div className="Wrapper__overload">
        {!isDesktop ? <h2 className="Categories__title">{title}</h2> : null}
        <ProductCarousel
          arrowsMini
          flatOnMobile
          chainedScroll
          slidesPerPage={slidesPerPage}
          slidesToScroll={slidesToScroll}
          infinite={false}
          title={<h2 className="Categories__title">{title}</h2>}
        >
          {products.map((product, i) =>
            product && city ? (
              <div key={product.id}>
                <CategoryCard {...getCardProps(product, i)} />
              </div>
            ) : null,
          )}
        </ProductCarousel>
      </div>
    </div>
  );
};
