import { t } from "@lingui/macro";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFilters } from "../../../../stores/filter/reducers";
import Checkbox from "../../../Checkbox";
import { ItemsExpand } from "../../../ItemsExpand";
import "./GeneralFilterContent.css";
import ToggleSwitch from "../../../ToggleSwitch";

const GeneralFilterSection = ({ title, children }) => (
  <div className="GeneralFilterContent__filter">
    <div className="GeneralFilterContent__filter_title">{title}</div>
    {children}
  </div>
);

export default function GeneralFilterContent({
  categories,
  availableDurations,
  initGeneralFilters,
  isMobile,
  i18n,
}) {
  const dispatch = useDispatch();
  const filtersState = useSelector(({ filters }) => filters);
  const formRef = useRef(null);

  const handleValuesChange = (fieldName, single) => {
    const method = single ? "get" : "getAll";
    const updatedValue = new FormData(formRef.current)[method](fieldName);

    if (fieldName === "duration") {
      updateFilters(dispatch, JSON.parse(updatedValue));
    } else updateFilters(dispatch, { [fieldName]: updatedValue });
  };

  return (
    <form ref={formRef} className="GeneralFilterContent">
      {!isMobile && categories && categories.length > 0 && (
        <GeneralFilterSection title={i18n._(t`Categories`)}>
          <ItemsExpand
            items={categories.map(item => (
              <Checkbox
                key={item.category.id}
                checked={filtersState.categoryId?.includes(String(item.category.id))}
                name="categoryId"
                value={item.category.id}
                onChange={() => handleValuesChange("categoryId")}
              >
                <span className="GeneralFilterContent__filter_item">{item.category.title}</span>{" "}
              </Checkbox>
            ))}
          />
        </GeneralFilterSection>
      )}

      <GeneralFilterSection title={i18n._(t`Rating`)}>
        <div className="GeneralFilterContent__filter_rating-container">
          <div>
            <div className="GeneralFilterContent__filter_subtitle">
              {i18n._(t`Top-rated activities`)}
            </div>
            <div className="GeneralFilterContent__filter_description">
              {i18n._(t`Highly rated activities according to the travelers reviews`)}
            </div>
          </div>
          <ToggleSwitch
            name="ratingGte"
            defaultChecked={filtersState.ratingGte}
            value={4}
            onChange={() => handleValuesChange("ratingGte", true)}
          />
        </div>
      </GeneralFilterSection>

      {!!availableDurations.length && (
        <GeneralFilterSection title={i18n._(t`Duration`)}>
          {availableDurations.map(
            ({
              category,
              durationMin = initGeneralFilters.durationGte,
              durationMax = initGeneralFilters.durationLte,
            }) => {
              const defaultChecked =
                // eslint-disable-next-line eqeqeq
                filtersState.durationGte == durationMin && filtersState.durationLte == durationMax;

              return (
                <Checkbox
                  key={category}
                  type="radio"
                  checked={defaultChecked}
                  name="duration"
                  value={JSON.stringify({ durationGte: durationMin, durationLte: durationMax })}
                  onChange={() => handleValuesChange("duration", true)}
                  onClick={e => {
                    if (defaultChecked && e.target.checked) {
                      updateFilters(dispatch, {
                        durationGte: initGeneralFilters.durationGte,
                        durationLte: initGeneralFilters.durationLte,
                      });
                    }
                  }}
                >
                  <span className="GeneralFilterContent__filter_item">{category}</span>
                </Checkbox>
              );
            },
          )}
        </GeneralFilterSection>
      )}
    </form>
  );
}
