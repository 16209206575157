/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { connect, useDispatch, useSelector } from "react-redux";
import { Trans } from "@lingui/macro";
import get from "lodash/get";
import { JsonLd } from "react-schemaorg";
import { isEmpty, sortBy } from "lodash";
import LazyHydrate from "react-lazy-hydration";
import * as types from "../../stores/types";
import { isMobileUserAgent, getUserAgent } from "../../functions/userAgent";
import { Api } from "../../functions/fetchFromApi";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import { fetchDefaultCurrency } from "../../functions/currency";
import toQueryString from "../../functions/toQueryString";
import { sendListViewedEvent } from "../../functions/analytics";
import { fetchLinkings, fetchOverview } from "../../functions/fetchData";
import reverseUrl from "../../functions/reverseUrl";
import replaceImgTagsInHtml from "../../functions/optimizeImagesInHtml";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import Root from "../../components/_Root";
import { isSSR } from "../../components/NoSSR";
import ScrollHook from "../../components/ScrollHook";
import { CanonicalAuto } from "../../components/Canonical";
import Hero from "../../components/Hero";
import ProductsGrid from "../../components/ProductsGrid";
import { TopAttractions } from "../../components/Attractions";
import { CustomerReviews } from "../../components/Reviews";
import { getProductLink } from "../../functions/getProductLink";
import getDomainZone from "../../functions/url/getDomainZone";
import { AlternateAuto } from "../../components/Alternate";
import LinkingsGroup, { LinkingsGroups } from "../../components/Linking";
import ProductsFilter from "../../components/ProductsFilter";
import { Icon } from "../../components/Icon";
import StackedAccordions from "../../components/StackedAccordions";
import AudioGuideSection from "../../components/AudioGuideSection/Index";
import EmailSubscriptionSection from "../../components/EmailSubscriptionSection";
import MostRecommended from "../../components/MostRecommended";
import { Categories } from "../../components/Categories";
import { BestActivities } from "../../components/BestActivities";
import getDestinationLinksList from "../../functions/getDestinationLinksList";
import ButtonlikeLinksBlock from "../../components/ButtonlikeLinksBlock";
// import { OnDemandToursBanner } from "../../components/OnDemandToursBanner";
import { SitemapBlock } from "../../components/SitemapBlock";
import "./City.css";
import { usePageViewTracking } from "../../functions/usePageViewTracking";
import useHighlightedProduct, {
  useScrollToProductGrid,
} from "../../functions/useHighlightedProduct";
import { fetchCityAttractionsWithTickets } from "../../functions/fetchCityAttractionsWithTickets";

// TODO: use hooks for redux
function City({
  mobile,
  city = {},
  products,
  destinations,
  seoHtml = "",
  path = "",
  reviews = {},
  linkings,
  filters,
  mostRecommended,
  overallCount,
  cityAttractionsWithoutTickets,
  interests,
  recommendedCities,
  categories,
  userId,
}) {
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();
  const DNSZone = getDomainZone(lang);
  const currentPath = isSSR ? path : window.location.pathname;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isDesktop = !isSSR && useMediaQuery("(min-width: 992px)");
  const isMobile = isSSR ? mobile : useMediaQuery("(max-width: 767px)");
  usePageViewTracking("city", { userId, cityId: city.id });

  const cityName = city.name ? inclineIfNeeded(city.name, "in", lang) : "";
  const cityNameNearbySubtitle = city.name ? inclineIfNeeded(city.name, "from", lang) : "";
  const productListId = `city_${city.slug}_${city.id}`;

  /**
   * Product list became visible in viewport
   */
  const onProductListShown = () => {
    sendListViewedEvent(productListId, products.products, lang);
  };

  const citiesLinks = sortBy(
    getDestinationLinksList({
      name: "city",
      destinations: recommendedCities,
      destinationsParams: {
        cityId: "id",
        citySlug: "slug",
      },
      lang,
    }),
    ["text"],
  );
  const sortOrderState = useSelector(state => state.filters.order);

  const { sortedProducts, highlightProductId } = useHighlightedProduct(products, sortOrderState);
  useScrollToProductGrid(highlightProductId);

  return (
    <Root stickyHeader searchInHeader destinations={destinations}>
      <AlternateAuto route="city" />
      <CanonicalAuto route="city" />
      <Hero
        theme="no-background"
        city={city}
        initialMetaTitle={city.meta_title}
        initialMetaDescription={city.meta_description}
        current="city"
      />
      <div className="Wrapper City">
        <AudioGuideSection />
        {products.products.length > 0 && <BestActivities products={products} city={city} />}
        {get(city, "attractions.length") ? (
          <div>
            <h2 className="City__top-attractions_title">
              <Trans>Top sights in {cityName}</Trans>
            </h2>
            <TopAttractions attractions={city.attractions} city={city} isMobile={isMobile} />
          </div>
        ) : null}
        <h2 className="City__title-ProductsGrid">
          <Trans>All self-guided activities</Trans>
        </h2>
        <ProductsFilter
          isMobile={isMobile}
          isDesktop={isDesktop}
          categories={categories}
          dispatch={dispatch}
          city={city}
          lang={lang}
        />
        {/* this block was temporarily removed */}
        {/* <OnDemandToursBanner lang={lang} userId={user.user.id} /> */}
        <LazyHydrate whenVisible>
          <ScrollHook once="shown" showOn=".City__products" onChanged={onProductListShown} />
          <ProductsGrid
            className="City__products"
            listId={productListId}
            selectParams={{
              cityId: city?.id,
              ...filters,
              lang: filters?.lang?.length ? filters.lang : lang,
            }}
            {...products}
            products={sortedProducts}
            highlightProductId={highlightProductId}
          />

          {!products.loading &&
            products.products?.length === products.count &&
            city.products_data?.length > 0 && (
              <>
                <div className="City__nearbyProducts">
                  <div className="City__nearbyProducts_title">
                    <Icon name="search/thin" />
                    <Trans>No more things to do in {cityName}!</Trans>
                  </div>
                  <div className="City__nearbyProducts_subtitle">
                    <Trans>
                      Check out these {city.products_data.length} other activities nearby{" "}
                      {cityNameNearbySubtitle}
                    </Trans>
                  </div>
                </div>
                <ProductsGrid products={city.products_data} />
              </>
            )}
          {categories.length > 0 && !isEmpty(city) && (
            <Categories
              products={categories}
              lang={lang}
              city={city}
              title={<Trans>Immersive self-guided tours</Trans>}
            />
          )}
          <EmailSubscriptionSection city={city} />
          {mostRecommended.length > 0 && (
            <MostRecommended
              categoryTitle={null}
              city={city}
              mostRecommended={mostRecommended}
              lang={lang}
            />
          )}
          {!isEmpty(city) && cityName && lang && Array.isArray(interests) && (
            <LinkingsGroups
              interests={interests}
              dispatch={dispatch}
              cityId={city.id}
              cityAttractionsWithoutTickets={cityAttractionsWithoutTickets?.results || []}
              fetchCityAttractionsWithTickets={() =>
                fetchCityAttractionsWithTickets({
                  cityId: city.id,
                  countryId: city.country?.id,
                  lang,
                })
              }
              city={city}
              cityName={cityName}
              lang={lang}
            />
          )}
          {!!recommendedCities?.length && city?.country?.name && (
            <ButtonlikeLinksBlock
              slideShow={isMobile}
              titleText={<Trans>Cities in {city.country.name}</Trans>}
              links={citiesLinks}
            />
          )}

          <LinkingsGroup onlyPopularBlock linkings={linkings} city={city} lang={lang} />
          {city.id && (
            <CustomerReviews
              showOverallRating
              overallCount={overallCount}
              withPopup={isMobile}
              lang={lang}
              isMobile={isMobile}
              queryParams={{ cityId: city.id }}
              title={<Trans>Reviews about activities in {cityName}</Trans>}
            />
          )}
          <StackedAccordions data={city.faqs} TitleTag="h2">
            <Trans>About {city.name}</Trans>
          </StackedAccordions>
          {city?.description ? (
            <div
              className="City__seo"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: seoHtml,
              }}
            />
          ) : null}
          {!isEmpty(city) && <SitemapBlock city={city} lang={lang} cityName={cityName} />}
        </LazyHydrate>
        <JsonLd
          item={{
            "@context": "https://schema.org",
            "@type": "Product",
            url: `https://wegotrip.${DNSZone}${currentPath}`,
            sku: city.id,
            name: city.name,
            description: city.meta_description,
            brand: { "@type": "Brand", name: "WeGoTrip" },
            image: city.preview,
            ...(reviews.count
              ? {
                  aggregateRating: {
                    "@type": "AggregateRating",
                    ratingValue: reviews.averageRating,
                    reviewCount: reviews.count,
                  },
                }
              : {}),
            ...(reviews.count
              ? {
                  review: reviews.reviews.map(review => ({
                    "@type": "Review",
                    author: { "@type": "Person", name: review.name },
                    datePublished: review.date,
                    description: review.text,
                    reviewRating: {
                      "@type": "Rating",
                      bestRating: "5",
                      ratingValue: review.rating,
                      worstRating: "1",
                    },
                  })),
                }
              : {}),
            ...(products.count
              ? {
                  offers: products.products.map(product => ({
                    "@type": "Offer",
                    price: product.price,
                    priceCurrency: product.currencyCode,
                    url: `https://wegotrip.${DNSZone}${getProductLink(lang, product)}`,
                    availability: "https://schema.org/InStock",
                  })),
                }
              : {}),
          }}
        />
      </div>
    </Root>
  );
}

City.getInitialProps = withRedirectToKnownLang(async ({ req, match, store }) => {
  try {
    const cookies = get(req, "headers.cookie");
    const mobile = isMobileUserAgent(getUserAgent(req));
    store.dispatch({ type: types.FETCH_USER, cookies });

    const currency = await fetchDefaultCurrency(req);
    store.dispatch({ type: types.SET_DEFAULT_CURRENCY, defaultCurrency: currency });

    const { cityId } = match.params;
    const lang = getCurrentLanguage(match.params.lang);
    const { data: city } = await Api.get(
      `/api/v2/cities/${cityId}/?${toQueryString({
        lang,
        expand: "h1,meta_title,meta_description,description",
        preorder: true,
      })}`,
      { lang },
    );
    store.dispatch({ type: types.FETCH_PRODUCTS, cityId, lang });
    store.dispatch({ type: types.FETCH_LANGUAGES });

    const overview = await fetchOverview({ lang }, req);
    store.dispatch({ type: types.SET_OVERVIEW, data: overview });

    store.dispatch({
      type: types.FETCH_MOST_RECOMMENDED,
      payload: { city: cityId, lang },
    });

    store.dispatch({
      type: types.FETCH_OVERALL_RATING,
      payload: { id: cityId, type: "city", lang },
    });

    let cityAttractionsWithoutTickets;
    if (city.country?.id) {
      cityAttractionsWithoutTickets = await Api.get(
        `/api/v3/attractions/?${toQueryString({
          lang,
          city_id: cityId,
          country_id: city.country.id,
          include_preorder: false,
          per_page: 20,
          sort_by: "popularity",
        })}&with_tickets=false`,
        { lang },
      );
    }

    store.dispatch({
      type: types.FETCH_CATEGORIES,
      payload: { cityId, lang },
    });

    store.dispatch({
      type: types.FETCH_INTERESTS,
      payload: { cityId, lang },
    });

    const {
      reviews: { count: reviewsCount },
    } = store.getState();
    if (!reviewsCount) {
      store.dispatch({
        type: types.FETCH_REVIEWS,
        lang,
        cityId,
        per_page: mobile ? 3 : 6,
      });
    }

    store.dispatch({
      type: types.FETCH_CITIES,
      payload: {
        countryId: city.country.id,
        lang,
      },
    });

    const seoHtml = replaceImgTagsInHtml(city.description) || "";

    /**
     * Redirecting to main page if no tours presented
     * Runs only if `bubbling` query param is set
     */
    if (get(req, "query.bubbling") && !city.itemsCount) {
      return {
        redirectTo: reverseUrl("main", {
          lang,
        }),
      };
    }

    const linkings = await fetchLinkings({ lang, id: cityId });
    return {
      destinations: overview.topCities,
      cityAttractionsWithoutTickets,
      city,
      mobile,
      seoHtml,
      path: req?.originalUrl,
      linkings,
    };
  } catch (error) {
    // TODO: serve 500 for not 404 errors
    return { statusCode: 404 };
  }
});

const mapStateToProps = ({ products, user, reviews, cities, filters }) => ({
  products: products || {},
  userId: user.user.id,
  mostRecommended: products.mostRecommendedProducts,
  overallCount: reviews.overallCount,
  interests: cities.interests,
  recommendedCities: cities.cities,
  categories: cities.categories,
  reviews,
  filters,
});

export default connect(mapStateToProps)(City);
