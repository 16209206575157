import React, { useState, useEffect } from "react";
import classNames from "../../functions/classNames";
import "./CoverImage.css";

const imageCache = new Map();

function CoverImage({
  src,
  alt,
  width,
  height,
  className,
  onLoad,
  loading = "lazy",
  decoding = "async",
  sizes = "(max-width: 767px) 100vw, (max-width: 991px) 50vw, 33vw",
  ...props
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!src) return;

    // Проверяем кэш
    if (imageCache.has(src)) {
      setIsLoaded(true);
      onLoad?.();
      return;
    }

    const img = new Image();
    img.src = src;
    img.decoding = decoding;
    img.sizes = sizes;

    img.onload = () => {
      imageCache.set(src, true);
      setIsLoaded(true);
      onLoad?.();
    };

    img.onerror = () => {
      setError(true);
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src, decoding, sizes, onLoad]);

  if (!src || error) {
    return null;
  }

  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      loading={loading}
      decoding={decoding}
      sizes={sizes}
      className={classNames("CoverImage", isLoaded && "CoverImage--loaded", className)}
      {...props}
    />
  );
}

export default React.memo(CoverImage);
