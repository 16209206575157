import { Api } from "./fetchFromApi";
import toQueryString from "./toQueryString";

export const fetchCityAttractionsWithTickets = async ({ cityId, countryId, lang }) => {
  if (countryId) {
    const response = await Api.get(
      `/api/v3/attractions/?${toQueryString({
        lang,
        city_id: cityId,
        country_id: countryId,
        include_preorder: false,
        per_page: 20,
        sort_by: "popularity",
        with_tickets: true,
      })}`,
      { lang },
    );
    return response;
  }
  return { results: [] };
};
