import { t } from "@lingui/macro";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import * as types from "../../../stores/types";
import Button from "../../Button";
import getFilterButtonsProps from "../getFilterButtonsProps";
import PopupOrDropdownAdapter from "../../PopupOrDropdownAdapter";
import ChevronIcon from "../../ChevronIcon";
import { PriceSelection } from "./PriceSelection";

import "./PriceFilter.css";

function PriceFilter({ currency, isMobile, i18n, dispatch, onApply = () => {} }) {
  const { maxPrice, minPrice } = useSelector(({ filters }) => ({
    maxPrice: filters.priceLte,
    minPrice: filters.priceGte,
  }));

  const { prefetchedCount, defaultMaxPrice } = useSelector(({ products }) => ({
    prefetchedCount: products.prefetched.count,
    defaultMaxPrice: Math.ceil(products.queryData?.maxPrice) || 100,
  }));

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const applyFilters = () => {
    onApply();
  };

  const shouldShowSelection = maxPrice || minPrice;

  const getButtonText = () => {
    if (!shouldShowSelection) return i18n._(t`Price`);
    return `${i18n._(t`Up to`)} ${maxPrice || defaultMaxPrice} ${currency}`;
  };

  const popupButtons = useMemo(
    () =>
      getFilterButtonsProps({
        onReset: () => {
          dispatch({ type: types.RESET_PRICE_FILTERS });
        },
        prefetchedCount,
        i18n,
      }),
    [dispatch, i18n, prefetchedCount],
  );

  return (
    <PopupOrDropdownAdapter
      isMobile={isMobile}
      title={i18n._(t`Price`)}
      triggerElement={
        <Button
          theme={shouldShowSelection ? "black" : "default"}
          icon={!isMobile && <ChevronIcon isOpen={isPopupOpen} />}
          className="PriceAndDate__button"
          size="M"
        >
          {getButtonText()}
        </Button>
      }
      {...popupButtons}
      ContentComponent={() => (
        <PriceSelection currency={currency} dispatch={dispatch} defaultMaxPrice={defaultMaxPrice} />
      )}
      onOpened={() => setIsPopupOpen(true)}
      onClosed={() => {
        setIsPopupOpen(false);
        applyFilters();
      }}
      onStateChange={setIsPopupOpen}
    />
  );
}

export default PriceFilter;
