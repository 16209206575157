import { sortBy } from "lodash";
import get from "lodash/get";
import React, { useCallback, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useMediaQuery } from "beautiful-react-hooks";
import { connect, useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";
import { JsonLd } from "react-schemaorg";
import LazyHydrate from "react-lazy-hydration";
import routes from "../../routes";
import * as types from "../../stores/types";
import { isMobileUserAgent, getUserAgent } from "../../functions/userAgent";
import { Api } from "../../functions/fetchFromApi";
import detectRoute from "../../functions/route/detectRoute";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import { fetchDefaultCurrency } from "../../functions/currency";
import toQueryString from "../../functions/toQueryString";
import { sendListViewedEvent } from "../../functions/analytics";
import { fetchLinkings, fetchOverview } from "../../functions/fetchData";
import reverseUrl from "../../functions/reverseUrl";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import Root from "../../components/_Root";
import { isSSR } from "../../components/NoSSR";
import ScrollHook from "../../components/ScrollHook";
import TextExpand from "../../components/TextExpand";
import Hero from "../../components/Hero";
import StackedAccordions from "../../components/StackedAccordions";
import { CustomerReviews } from "../../components/Reviews";
import { getProductLink } from "../../functions/getProductLink";
import replaceImgTagsInHtml from "../../functions/optimizeImagesInHtml";
import getDomainZone from "../../functions/url/getDomainZone";
import { CanonicalAuto } from "../../components/Canonical";
import { AlternateAuto } from "../../components/Alternate";
import LinkingsGroup, { LinkingsGroups } from "../../components/Linking";
import ProductsGridAdapter from "../../components/ProductsGrid/ProductsGridAdapter";
import ProductsFilter from "../../components/ProductsFilter";

import "../City/City.css";
import "./Tickets.css";
import ButtonlikeLinksBlock from "../../components/ButtonlikeLinksBlock";
import AudioGuideSection from "../../components/AudioGuideSection/Index";
import { Categories } from "../../components/Categories";
import CityLinkSection from "../../components/CityLinkSection";
import EmailSubscriptionSection from "../../components/EmailSubscriptionSection";
import MostRecommended from "../../components/MostRecommended";
import { fetchCityAttractionsWithTickets } from "../../functions/fetchCityAttractionsWithTickets";
import { SitemapBlock } from "../../components/SitemapBlock";
import getDestinationLinksList from "../../functions/getDestinationLinksList";

// TODO: use hooks for redux
function Tickets({
  city = {},
  products = {},
  attraction,
  destinations,
  linkings,
  seoHtml = "",
  mostRecommended,
  overallCount,
  path = "",
  reviews = {},
  attractionId,
  cityAttractionsWithoutTickets,
  recommendedCities,
  productsPerPage,
  currency,
  interests,
  filters,
}) {
  const { categoryId } = useParams();
  const lang = useCurrentLanguage();
  const DNSZone = getDomainZone(lang);
  const cityId = city.id;
  const currentPath = isSSR ? path : window.location.pathname;
  const { pathname } = useLocation();
  const route = detectRoute(
    routes.filter(r => /attraction-tickets/i.test(r.name)),
    pathname,
  );
  const isMobile = useMediaQuery("(max-width: 767px)");
  const isMobileView = !isSSR && isMobile;
  const isDesktop = useMediaQuery("(min-width: 992px)");
  const dispatch = useDispatch();

  const category = categoryId
    ? city.categories.find(cat => cat.id === parseInt(categoryId, 10))
    : null;

  const memoizedData = useMemo(
    () => ({
      city: { ...city },
      attraction: { ...attraction },
      cityName: inclineIfNeeded(city.name, "in", lang),
      productListId: `attraction_${attraction?.slug}_${attraction?.id}`,
      citiesLinks: sortBy(
        getDestinationLinksList({
          name: "city",
          destinations: recommendedCities,
          destinationsParams: {
            cityId: "id",
            citySlug: "slug",
          },
          lang,
        }),
        ["text"],
      ),
    }),
    [city, attraction, lang, recommendedCities],
  );

  const { cityName, productListId, citiesLinks } = memoizedData;

  const onProductListShown = useCallback(() => {
    sendListViewedEvent(productListId, products.products, lang);
  }, [productListId, products.products, lang]);

  const defaultProductsGridProps = {
    listId: productListId,
    perPage: productsPerPage,
    isMobile,
    lang,
  };
  const findPriceByCurrency = (prices, currencyCode) => {
    return prices.find(price => price.currencyCode === currencyCode);
  };

  const lowestPrice = findPriceByCurrency(city.minPrice, currency);
  return (
    <Root stickyHeader searchInHeader destinations={destinations}>
      {route ? <AlternateAuto route={route} /> : null}
      {route ? <CanonicalAuto route={route} /> : route}
      <Hero
        withTickets
        city={city}
        lowestPrice={lowestPrice}
        category={category}
        attraction={attraction}
        current="attraction"
        theme="no-background"
      />
      <div className="Tickets Wrapper">
        <LazyHydrate whenVisible>
          <ScrollHook once="shown" showOn=".Tickets__products" onChanged={onProductListShown} />
          <AudioGuideSection />
          <div>
            <div className="Tickets__products-filter_title">
              <Trans>All self-guided activities</Trans>
            </div>
            {!!products.products?.length && (
              <>
                <ProductsFilter
                  isMobile={isMobile}
                  isDesktop={isDesktop}
                  dispatch={dispatch}
                  attraction={attraction}
                  lang={lang}
                />
                <ProductsGridAdapter
                  {...defaultProductsGridProps}
                  products={products.products}
                  queryData={{
                    loading: products.loading || false,
                    current: products.current || 1,
                    next: products.next,
                    count: products.count || 0,
                    pages: products.pages || 1,
                  }}
                  selectParams={{
                    cityId,
                    currency,
                    attractionId,
                    ...filters,
                    lang: filters?.lang?.length ? filters.lang : lang,
                  }}
                  limitDisplay={false}
                />
              </>
            )}
          </div>
        </LazyHydrate>

        <LazyHydrate whenVisible>
          {memoizedData.attraction?.nearbyAttractions?.length > 0 && (
            <Categories
              products={memoizedData.attraction.nearbyAttractions}
              lang={lang}
              city={memoizedData.city}
              type="attraction"
              title={<Trans>Top sights near {memoizedData.attraction?.name}</Trans>}
            />
          )}

          <CityLinkSection city={memoizedData.city} lang={lang} />

          <EmailSubscriptionSection city={memoizedData.city} attractionId={attractionId} />

          <MostRecommended
            categoryTitle={memoizedData.attraction?.name || null}
            city={memoizedData.city}
            mostRecommended={mostRecommended}
            lang={lang}
          />

          <CustomerReviews
            showOverallRating
            overallCount={overallCount}
            withPopup={isMobileView}
            lang={lang}
            isMobile={isMobileView}
            queryParams={{ attractionId }}
            title={<Trans>What people say about {memoizedData.attraction?.name || cityName}</Trans>}
          />

          <LinkingsGroups
            interests={interests}
            dispatch={dispatch}
            cityAttractionsWithoutTickets={cityAttractionsWithoutTickets?.results || []}
            fetchCityAttractionsWithTickets={() =>
              fetchCityAttractionsWithTickets({
                cityId,
                countryId: memoizedData.city.country?.id,
                lang,
              })
            }
            city={memoizedData.city}
            cityId={cityId}
            cityName={cityName}
            lang={lang}
          />

          {!!recommendedCities?.length && memoizedData.city?.country?.name && (
            <ButtonlikeLinksBlock
              slideShow={isMobile}
              titleText={<Trans>Cities in {memoizedData.city.country.name}</Trans>}
              links={citiesLinks}
            />
          )}

          <LinkingsGroup
            onlyPopularBlock
            linkings={linkings}
            city={memoizedData.city}
            lang={lang}
          />

          {seoHtml && (
            <TextExpand
              html
              allowOriginalFormat
              shortText={seoHtml
                .slice(0, 300)
                .replace(/<\/?(tr|td|ul|li|p|b|div|span|a)[^>]*(>|$)/gi, "")}
              fullText={seoHtml}
            />
          )}

          <StackedAccordions data={memoizedData.attraction?.abouts} TitleTag="h2">
            <Trans>About {memoizedData.attraction?.name}</Trans>
          </StackedAccordions>

          <StackedAccordions data={memoizedData.attraction?.faqs}>
            <Trans>FAQ about {memoizedData.attraction?.name}</Trans>
          </StackedAccordions>

          <SitemapBlock city={memoizedData.city} lang={lang} cityName={cityName} />
        </LazyHydrate>

        <JsonLd
          item={{
            "@context": "https://schema.org",
            "@type": "Product",
            url: `https://wegotrip.${DNSZone}${currentPath}`,
            sku: (attraction && attraction.id) || city.id,
            name: (attraction && attraction.name) || city.name,
            description: (attraction && attraction.meta_description) || city.meta_description,
            brand: { "@type": "Brand", name: "WeGoTrip" },
            image: (attraction && attraction.preview) || city.preview,
            ...(reviews.count
              ? {
                  aggregateRating: {
                    "@type": "AggregateRating",
                    ratingValue: reviews.averageRating,
                    reviewCount: reviews.count,
                  },
                }
              : {}),
            ...(reviews.count
              ? {
                  review: reviews.reviews.map(review => ({
                    "@type": "Review",
                    author: { "@type": "Person", name: review.name },
                    datePublished: review.date,
                    description: review.text,
                    reviewRating: {
                      "@type": "Rating",
                      bestRating: "5",
                      ratingValue: review.rating,
                      worstRating: "1",
                    },
                  })),
                }
              : {}),
            ...(products.count
              ? {
                  offers: products.products.map(product => ({
                    "@type": "Offer",
                    price: product.price,
                    priceCurrency: product.currencyCode,
                    url: `https://wegotrip.${DNSZone}${getProductLink(lang, product)}`,
                    availability: "https://schema.org/InStock",
                  })),
                }
              : {}),
          }}
        />
        {attraction.faq_ticket && (
          <JsonLd
            item={{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              mainEntity: attraction.faq_ticket.map(question => ({
                "@type": "Question",
                name: question.title,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: question.body,
                },
              })),
            }}
          />
        )}
      </div>
    </Root>
  );
}

Tickets.getInitialProps = withRedirectToKnownLang(
  // eslint-disable-next-line no-unused-vars
  async ({ req, res, match, history, location, store, scrollToTop }) => {
    try {
      const cookies = get(req, "headers.cookie");
      const mobile = isMobileUserAgent(getUserAgent(req));
      store.dispatch({ type: types.FETCH_USER, cookies });

      const currency = await fetchDefaultCurrency(req);
      store.dispatch({ type: types.SET_DEFAULT_CURRENCY, defaultCurrency: currency });

      const { attractionId } = match.params;
      const lang = getCurrentLanguage(match.params.lang);
      let { cityId } = match.params;
      let result = {};
      let seoHtml = "";

      store.dispatch({ type: types.FETCH_LANGUAGES });

      const overview = await fetchOverview({ lang }, req);
      store.dispatch({ type: types.SET_OVERVIEW, data: overview });

      if (attractionId) {
        const { data: attraction } = await Api.get(
          `/api/v2/attractions/${attractionId}/?${toQueryString({
            lang,
            expand:
              "h1_dupl,description_dupl,meta_title_dupl,meta_description_dupl,schedule,about_ticket,faq_ticket",
            preorder: true,
          })}`,
          {
            lang,
          },
        );
        result = { ...result, attraction };

        // Redirect to 404 page if no tickets presented
        if (!attraction.fee || !attraction.entrance) {
          return {
            redirectTo: reverseUrl("error", {
              lang,
            }),
          };
        }

        // Redirect from the long attraction route to the short one
        if (cityId) {
          return {
            redirectTo: reverseUrl("attraction-tickets-simple", {
              attractionId,
              attractionSlug: attraction.slug,
              lang,
            }),
          };
        }

        if (!cityId && attraction.city) {
          // eslint-disable-next-line require-atomic-updates
          cityId = attraction.city.id;
        }
        seoHtml = attraction.description_dupl;
      }

      const productsPerPage = mobile ? 4 : 8;
      store.dispatch({
        type: types.FETCH_PRODUCTS,
        lang,
        currency,
        perPage: productsPerPage,
        attractionId,
      });

      const city = (
        await Api.get(
          `/api/v2/cities/${cityId}/?${toQueryString({
            lang,
            expand: "h1,meta_title,meta_description,description",
            preorder: true,
          })}`,
          { lang },
        )
      ).data;

      let cityAttractionsWithoutTickets;
      if (city.country?.id) {
        cityAttractionsWithoutTickets = await Api.get(
          `/api/v3/attractions/?${toQueryString({
            lang,
            city_id: cityId,
            country_id: city.country.id,
            include_preorder: false,
            per_page: 20,
            sort_by: "popularity",
          })}&with_tickets=false`,
          { lang },
        );
      }

      store.dispatch({
        type: types.FETCH_CATEGORIES,
        payload: { cityId, lang },
      });

      store.dispatch({
        type: types.FETCH_INTERESTS,
        payload: { cityId, lang },
      });

      store.dispatch({
        type: types.FETCH_MOST_RECOMMENDED,
        payload: { city: cityId, attraction: attractionId, lang },
      });

      const {
        reviews: { count: reviewsCount },
      } = store.getState();
      if (!reviewsCount) {
        store.dispatch({
          type: types.FETCH_REVIEWS,
          lang,
          attractionId,
          per_page: mobile ? 3 : 6,
        });
      }

      store.dispatch({
        type: types.FETCH_OVERALL_RATING,
        payload: { id: attractionId, type: "attraction", lang },
      });

      store.dispatch({
        type: types.FETCH_CITIES,
        payload: {
          countryId: city.country.id,
          lang,
        },
      });

      /**
       * Redirecting to main page if no tours presented
       * Runs only if `bubbling` query param is set
       */
      if (req && get(req, "query.bubbling") && city) {
        return {
          redirectTo: reverseUrl("main", {
            lang,
          }),
        };
      }

      const linkings = await fetchLinkings({ lang, id: cityId });

      result = {
        ...result,
        destinations: overview.topCities,
        city,
        productsPerPage,
        cityAttractionsWithoutTickets,
        currency,
        attractionId,
        mobile,
        linkings,
        seoHtml: replaceImgTagsInHtml(seoHtml),
        path: req?.originalUrl,
      };

      return result;
    } catch (error) {
      // TODO: serve 500 for not 404 errors
      return { statusCode: 404 };
    }
  },
);

const mapStateToProps = ({ products, user, reviews, filters, cities }) => ({
  products,
  cityProducts: products.cityProducts,
  mostRecommended: products.mostRecommendedProducts,
  user,
  categories: cities.categories,
  interests: cities.interests,
  reviews,
  overallCount: reviews.overallCount,
  filters,
  recommendedCities: cities.cities,
});

export default connect(mapStateToProps)(Tickets);
