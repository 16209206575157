import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Trans } from "@lingui/macro";
import { useDispatch } from "react-redux";
import ProductsGrid from "..";
import ProductsTabs from "../../ProductsTabs";
import Popup from "../../Popup";
import Button from "../../Button";
import Spinner from "../../Spinner";
import useOnScreen from "../../../functions/customHooks/useOnScreen";
import memoizedState from "../../../functions/react/memoizedState";
import { FETCH_PRODUCTS } from "../../../stores/types";

import "./ProductsGridAdapter.css";
import Placeholder from "../../Placeholder";

const callOnPageChange = memoizedState(1);
/**
 * ProductsGridAdapter
 * A component for displaying products with optional popup functionality.
 * @component
 * @param {boolean} isMobile - Flag indicating if the component is in mobile mode (popup mode).
 * @param {string} title - Title of the component.
 * @param {Array} products - Array of products to display.
 * @param {number} next - Next page number for fetching more products.
 * @param {Object} selectParams - Parameters for product selection.
 * @param {string} className - Additional CSS class for the component.
 * @param {number} perPage - Number of products per page.
 * @param {string} listId - Identifier for the product list.
 * @param {number} count - Total count of products.
 * @param {Object} queryData - Data related to the query.
 * @param {number} queryData.current - Current query data.
 * @param {string} lang - Language of the products.
 * @param {string} actionType - Action type for fetching products.
 * @param {Function} onPaginate - Callback function triggered on pagination.
 * @param {boolean} showMorePaginate - Flag indicating if more products should be paginated.
 * @param {boolean} limitDisplay - Flag indicating if the products should be limited.
 * @returns {React.ReactNode} - Rendered component.
 */

export default function ProductsGridAdapter({
  isMobile: isPopup,
  title,
  products = [],
  selectParams,
  className: gridClassName,
  perPage,
  listId,
  queryData = {},
  lang,
  onPaginate = () => {},
  showMorePaginate = false,
  withoutFiltersPage = false,
  limitDisplay,
  ...props
}) {
  const dispatch = useDispatch();
  const { loading, current, next, count, pages } = queryData;

  const fetchProducts = useCallback(
    ({ append, index }) => {
      const params = {
        type: props.actionType || FETCH_PRODUCTS,
        page: index || next,
        append,
        perPage,
        ...selectParams,
      };

      if (!withoutFiltersPage) {
        params.lang = selectParams.lang !== undefined ? selectParams.lang : lang;
      }

      dispatch(params);
    },
    [dispatch, props.actionType, next, perPage, selectParams, lang, withoutFiltersPage],
  );

  const handleShowMore = useCallback(() => {
    if (next && !loading) {
      fetchProducts({ append: true });
      onPaginate(next);
    }
  }, [fetchProducts, next, loading, onPaginate]);

  const WrapperTag = useMemo(() => (isPopup ? Popup : ProductsTabs), [isPopup]);

  const wrapperProps = useMemo(
    () => ({
      className: "ProductsGridAdapter",
      ...(isPopup
        ? {
            rendered: true,
            title,
            theme: "bottomSheet",
            triggerElement: count > perPage && (
              <Button size="L" theme="card" onClick={() => {}}>
                <Trans>Show more</Trans>
              </Button>
            ),
          }
        : {
            current,
            arrows: !showMorePaginate,
            header: false,
            amount: pages || Math.ceil(count / perPage),
            onBeforeChange: index => {
              fetchProducts({ index });
              onPaginate(index);
            },
          }),
    }),
    [
      isPopup,
      title,
      current,
      pages,
      count,
      perPage,
      fetchProducts,
      onPaginate,
      showMorePaginate,
      handleShowMore,
    ],
  );
  const loadMoreHandler = () => {
    fetchProducts({ append: true });
    onPaginate(next);
  };

  const endOfProducts = useRef(null);
  const isEndOfProducts = useOnScreen(endOfProducts);
  const defaultProductsGridProps = useMemo(
    () => ({
      products,
      next,
      selectParams,
      perPage,
      listId,
      count,
      current,
      lang,
      showMoreButton: false,
      className: gridClassName,
    }),
    [products, next, selectParams, perPage, listId, count, current, lang, gridClassName],
  );

  useEffect(() => {
    if (isPopup && isEndOfProducts && next && !loading) {
      callOnPageChange({ next, selectParams }, () => {
        fetchProducts({ append: true });
        onPaginate(next);
      });
    }
  }, [isEndOfProducts, isPopup, next, onPaginate, loading, fetchProducts, selectParams]);

  if (loading) {
    return (
      <div className="ProductsGrid__loader">
        <Spinner size="large">
          <Trans>Tours are loading</Trans>
        </Spinner>
      </div>
    );
  } else if (!products.length && !loading) {
    return <Placeholder subtitle={<Trans>No tours presented here yet</Trans>} />;
  }

  return (
    !!products.length && (
      <>
        {isPopup && <ProductsGrid limitDisplay {...defaultProductsGridProps} title={title} />}
        {!showMorePaginate && (
          <WrapperTag {...wrapperProps}>
            <ProductsGrid
              {...defaultProductsGridProps}
              title={!isPopup && title}
              limitDisplay={limitDisplay}
            >
              {isPopup && next && (
                <div ref={endOfProducts} className="flex-center">
                  <Spinner />
                </div>
              )}
            </ProductsGrid>
          </WrapperTag>
        )}
        {showMorePaginate && !isPopup && (
          <>
            <ProductsGrid {...{ products, title, limitDisplay, className: gridClassName }} />
            {next && !loading && (
              <Button size="L" theme="card" onClick={loadMoreHandler}>
                <Trans>Show more</Trans>
              </Button>
            )}
          </>
        )}
      </>
    )
  );
}
