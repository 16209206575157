/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import uuid from "react-uuid";
import { isEmpty } from "lodash";
import { Trans } from "@lingui/macro";
import { useMediaQuery } from "beautiful-react-hooks";
import { useSelector } from "react-redux";
import getDestinationLinksList from "../../functions/getDestinationLinksList";
import combineCollectionsWithCategories from "../../functions/collections/combineCollectionsWithCategories";
import extractLinks from "../../functions/html/extractLinks";
import Link from "../Link";
import Spinner from "../Spinner";
import "./Linking.css";
import banner from "../../pages/ErrorPage/assets/banner.svg";
import { isSSR } from "../NoSSR";
import * as types from "../../stores/types";

/**
 * Block of links with title
 * @param {string} titleText - title of the block
 * @param {object[]} links - links to be displayed in block
 * @param {Function} setTextFormat - callback function to format a link text
 */

export function ToggleSwitch({ options, selectedOption, onToggle }) {
  const filteredOptions = options.filter(
    option => option.value !== null && option.value !== undefined && option.value !== "",
  );

  if (filteredOptions.length === 0) {
    return null;
  }

  return (
    <div className="Linking__toggleSwitch">
      {filteredOptions.map(option => (
        <div
          key={option.value}
          className={selectedOption === option.value ? "active" : ""}
          onClick={() => onToggle(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
}

export function Linking({
  titleText,
  links,
  setTextFormat = text => text,
  toggleOptions,
  selectedOption,
  isLoading,
  onToggle,
}) {
  if ((!links || links.length === 0) && (!toggleOptions || toggleOptions.length === 0)) {
    return null;
  }

  return (
    <div className="Linking">
      {titleText && <h3 className="Linking__title">{titleText}</h3>}

      {toggleOptions && toggleOptions.length > 0 && (
        <ToggleSwitch options={toggleOptions} selectedOption={selectedOption} onToggle={onToggle} />
      )}

      {isLoading ? (
        <div className="Linking__spinner">
          <Spinner />
        </div>
      ) : links?.length > 0 ? (
        <div className="Linking__block">
          {links.map(({ link, text }) => (
            <Link key={uuid()} external className="Linking__link" to={link} target="_blank">
              {setTextFormat(text)}
            </Link>
          ))}
        </div>
      ) : (
        <div className="Linking__empty">
          <img src={banner} alt="Banner" className="Linking__banner-image" />
          <p className="Linking__empty-text">Nothing here yet</p>
        </div>
      )}
    </div>
  );
}

export const addTicketsToursOnlyPopularBlock = _city => text => {
  const maxLength = 30;

  if (text.length > maxLength) {
    return `${text.slice(0, maxLength - 3)}...`;
  }

  return text;
};

export const addTicketsTours = text => {
  const suffix = "Tours";
  const maxLength = 24;

  return text.length + suffix.length > maxLength ? text : `${text} ${suffix}`;
};
const addCityName = _city => text => {
  return text;
};

export function LinkingsGroups({
  interests = [],
  city = {},
  cityAttractionsWithoutTickets = [],
  fetchCityAttractionsWithTickets,
  dispatch,
  cityId,
  lang,
  cityName,
}) {
  const [showTickets, setShowTickets] = useState(false);
  const [showCategories, setShowCategories] = useState(true);
  const [attractionsWithTickets, setAttractionsWithTickets] = useState([]);
  const [isLoadingTickets, setIsLoadingTickets] = useState(false);
  const isDesktop = !isSSR && useMediaQuery("(min-width: 992px)");

  const reduxTravelersInterests = useSelector(state => state.cities.travelersInterests || []);
  const isLoadingInterests = useSelector(state => state.cities.loadingTravelersInterests);

  const handleToggleTickets = async value => {
    try {
      setShowTickets(value);
      if (value && attractionsWithTickets.length === 0) {
        setIsLoadingTickets(true);
        const response = await fetchCityAttractionsWithTickets();
        setAttractionsWithTickets(response.results || []);
      }
    } catch (error) {
      console.error("Failed to fetch attractions with tickets:", error);
      setAttractionsWithTickets([]);
    } finally {
      setIsLoadingTickets(false);
    }
  };

  const handleToggleCategories = async value => {
    try {
      setShowCategories(value);
      if (!value && reduxTravelersInterests.length === 0) {
        await dispatch({
          type: types.FETCH_TRAVELERS_INTERESTS,
          payload: { cityId, lang },
        });
      }
    } catch (error) {
      console.error("Failed to fetch travelers interests:", error);
    }
  };

  const interestsArray = Array.isArray(interests) ? interests : [];
  const travelersInterestsArray = Array.isArray(reduxTravelersInterests)
    ? reduxTravelersInterests
    : [];

  const interestLinkProps = {
    name: "category",
    destinationsParams: {
      categoryId: "id",
      categorySlug: "slug",
    },
    additionalParams: {
      cityId: city.id,
      citySlug: city.slug,
    },
    lang,
  };

  const ticketLinkProps = {
    name: "attraction-tickets-simple",
    destinationsParams: {
      attractionId: "id",
      attractionSlug: "slug",
    },
    lang,
  };

  const simpleLinkProps = {
    name: "attraction-simple",
    destinationsParams: {
      attractionId: "id",
      attractionSlug: "slug",
    },
    lang,
  };

  const getSelectedAttractions = () => {
    if (!showTickets) {
      return cityAttractionsWithoutTickets;
    }
    if (isLoadingTickets) {
      return [];
    }
    return attractionsWithTickets;
  };

  const selectedAttractions = getSelectedAttractions();

  const toggleOptionsForTickets = [
    { label: <Trans>Self-guided tours</Trans>, value: false },
    { label: <Trans>Entrance tickets</Trans>, value: true },
  ];

  const hasInterests = interestsArray.length > 0;

  const toggleOptionsForCategories = hasInterests
    ? [
        { label: <Trans>Categories</Trans>, value: true },
        { label: <Trans>Travelers Interests</Trans>, value: false },
      ]
    : [];

  const shouldDisplayCategoriesToggle = hasInterests;
  const shouldDisplayTicketsToggle = cityAttractionsWithoutTickets.length > 0;

  const categoryLinks = (() => {
    if (isLoadingInterests && !showCategories) {
      return [];
    }

    const destinations = showCategories ? interestsArray : travelersInterestsArray;
    return getDestinationLinksList({
      ...interestLinkProps,
      destinations: destinations.map(interest => ({
        id: interest.category.id,
        title: interest.category.title,
        slug: interest.category.slug,
      })),
      isDesktop,
    });
  })();

  const attractionsLinks = selectedAttractions.length
    ? getDestinationLinksList({
        ...(showTickets ? ticketLinkProps : simpleLinkProps),
        destinations: selectedAttractions.map(attraction => ({
          id: attraction.id,
          title: attraction.name,
          slug: attraction.slug,
        })),
        isDesktop,
      })
    : [];

  return (
    <>
      {shouldDisplayCategoriesToggle && (
        <Linking
          titleText={<Trans>Things to do in {cityName}</Trans>}
          setTextFormat={addCityName(city.name)}
          toggleOptions={toggleOptionsForCategories}
          selectedOption={showCategories}
          isLoading={isLoadingInterests}
          links={categoryLinks}
          onToggle={handleToggleCategories}
        />
      )}

      {shouldDisplayTicketsToggle && (
        <Linking
          titleText={<Trans>Attractions in {cityName}</Trans>}
          setTextFormat={addTicketsToursOnlyPopularBlock(city.name, isDesktop)}
          toggleOptions={toggleOptionsForTickets}
          selectedOption={showTickets}
          isLoading={isLoadingTickets && showTickets}
          links={attractionsLinks}
          onToggle={handleToggleTickets}
        />
      )}

      <Linking titleText="Popular on WeGoTrip" links={[]} />
    </>
  );
}

/**
 * Group of `Linking` blocks:
 * `Attractions in {city}`,
 * `Trending attractions in {country}`,
 * `Things to do in {city}`,
 * `Popular on WeGoTrip`.
 * @param {object} linkings - object with `attractions_city`, `attractions_world`,`attractions_country`, `city_categories`, `city_collections` to be displayed as Linking blocks
 * @param {object} city - current city
 * @param {string} lang - current language
 */
export default function LinkingsGroup({
  linkings = {},
  city = {},
  countryName = city.country?.name,
  lang,
  onlyPopularBlock = false,
}) {
  if (isEmpty(linkings)) return null;

  // should be used instead of `CollectionsAndCategories` when `SubCategories` will be ready
  // const CollectionsAndSubCategories = linkings.city_categories.reduce((things, { subcategories }) => {
  //   return things.concat(subcategories);
  // }, linkings.city_collections);

  const ticketLinkProps = {
    name: "attraction-tickets-simple",
    destinationsParams: {
      attractionId: "id",
      attractionSlug: "slug",
    },
    lang,
  };

  return (
    <div className="LinkingsGroup">
      {city.name && !onlyPopularBlock && (
        <>
          <Linking
            titleText={`Attractions in ${city.name}`}
            setTextFormat={addTicketsTours}
            links={getDestinationLinksList({
              destinations: linkings.attractions_city,
              ...ticketLinkProps,
            })}
          />
          <Linking
            titleText={`Things to do in ${city.name}`}
            setTextFormat={addCityName(city.name)}
            links={getDestinationLinksList({
              name: "category",
              destinations: combineCollectionsWithCategories(
                linkings.city_collections,
                linkings.city_categories,
              ),
              destinationsParams: {
                categoryId: "id",
                categorySlug: "slug",
              },
              additionalParams: {
                cityId: city.id,
                citySlug: city.slug,
              },
              lang,
            })}
          />
          <Linking
            titleText={`Interesting about ${city.name}`}
            links={extractLinks(linkings.interesting_about)}
          />
        </>
      )}
      {!onlyPopularBlock && (
        <Linking
          titleText={`Trending attractions in ${countryName}`}
          setTextFormat={addTicketsTours}
          links={getDestinationLinksList({
            destinations: linkings.attractions_country,
            ...ticketLinkProps,
          })}
        />
      )}
      <Linking
        titleText={<Trans>Popular on WeGoTrip</Trans>}
        links={getDestinationLinksList({
          destinations: linkings.attractions_world,
          ...ticketLinkProps,
        })}
      />
    </div>
  );
}
