/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useMediaQuery } from "beautiful-react-hooks";
import { connect, useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";
import get from "lodash/get";
import { JsonLd } from "react-schemaorg";
import LazyHydrate from "react-lazy-hydration";
import * as types from "../../stores/types";
import routes from "../../routes";
import { isMobileUserAgent, getUserAgent } from "../../functions/userAgent";
import { Api } from "../../functions/fetchFromApi";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import detectRoute from "../../functions/route/detectRoute";
import { fetchDefaultCurrency } from "../../functions/currency";
import toQueryString from "../../functions/toQueryString";
import { fetchLinkings } from "../../functions/fetchData";
import reverseUrl from "../../functions/reverseUrl";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import Root from "../../components/_Root";
import { isSSR } from "../../components/NoSSR";
import { CanonicalAuto } from "../../components/Canonical";
import Hero from "../../components/Hero";
import ProductsGrid from "../../components/ProductsGrid";
import { CustomerReviews } from "../../components/Reviews";
import { getProductLink } from "../../functions/getProductLink";
import getDomainZone from "../../functions/url/getDomainZone";
import { AlternateAuto } from "../../components/Alternate";
import LinkingsGroup, { LinkingsGroups } from "../../components/Linking";
import StackedAccordions from "../../components/StackedAccordions";
import ProductsFilter from "../../components/ProductsFilter";
import EmailSubscriptionSection from "../../components/EmailSubscriptionSection";
import MostRecommended from "../../components/MostRecommended";
import CityLinkSection from "../../components/CityLinkSection";
import AudioGuideSection from "../../components/AudioGuideSection/Index";
import replaceImgTagsInHtml from "../../functions/optimizeImagesInHtml";
import { SitemapBlock } from "../../components/SitemapBlock";
import { findCategoryById } from "../../functions/findCategoryById";
import "./Collection.css";
import { usePageViewTracking } from "../../functions/usePageViewTracking";
import { fetchCityAttractionsWithTickets } from "../../functions/fetchCityAttractionsWithTickets";

function Collection({
  mobile,
  city = {},
  categories = {},
  linkings = {},
  cityAttractionsWithoutTickets,
  interests,
  products = {},
  mostRecommended = {},
  overallCount = {},
  path = "",
  reviews = {},
  lang,
  filters,
  userId,
}) {
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const DNSZone = getDomainZone(lang);
  const currentPath = isSSR ? path : window.location.pathname;
  const { pathname } = useLocation();
  const route = detectRoute(
    routes.filter(r => ["category", "subCategory"].includes(r.name)),
    pathname,
  );
  usePageViewTracking("category", { userId, categoryId });
  const category = categoryId
    ? city.categories.find(cat => cat.id === parseInt(categoryId, 10))
    : null;

  const collection = city?.collections?.find(item => item.category.id === Number(categoryId)) || {};
  const seoCategory = categories ? findCategoryById(categories, Number(categoryId)) : null;
  const seoHtml = replaceImgTagsInHtml(seoCategory?.description) || "";

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isDesktop = !isSSR && useMediaQuery("(min-width: 992px)");
  const isMobile = isSSR ? mobile : useMediaQuery("(max-width: 767px)");

  const cityName = city.name ? inclineIfNeeded(city.name, "in", lang) : "";
  const productListId = `city_${city.slug}_${city.id}`;

  return (
    <Root stickyHeader searchInHeader>
      {route ? <AlternateAuto route={route} /> : null}
      {route ? <CanonicalAuto route={route} /> : null}
      <Hero
        theme="no-background"
        city={city}
        initialMetaTitle={city.meta_title}
        initialMetaDescription={city.meta_description}
        category={category}
        current="category"
      />
      <div className="Wrapper Collection">
        <AudioGuideSection />
        <ProductsFilter
          isMobile={isMobile}
          isDesktop={isDesktop}
          dispatch={dispatch}
          city={city}
          lang={lang}
        />
        <ProductsGrid
          className="Collection__products"
          listId={productListId}
          selectParams={{
            cityId: city.id,
            ...filters,
            lang: filters?.lang?.length ? filters.lang : lang,
          }}
          {...products}
        />
        <LazyHydrate whenVisible>
          <CityLinkSection city={city} lang={lang} />
          <EmailSubscriptionSection city={city} categoryId={categoryId} />
          <MostRecommended
            categoryTitle={category ? category.title || null : null}
            city={city}
            mostRecommended={mostRecommended}
            lang={lang}
          />
          {city.id && (
            <CustomerReviews
              showOverallRating
              overallCount={overallCount}
              withPopup={isMobile}
              lang={lang}
              isMobile={isMobile}
              queryParams={{ cityId: city.id }}
              title={<Trans>Reviews about activities in {cityName}</Trans>}
            />
          )}

          <LinkingsGroups
            interests={interests}
            dispatch={dispatch}
            cityAttractionsWithoutTickets={cityAttractionsWithoutTickets?.results || []}
            fetchCityAttractionsWithTickets={() =>
              fetchCityAttractionsWithTickets({
                cityId: city.id,
                countryId: city.country?.id,
                lang,
              })
            }
            city={city}
            cityId={city.id}
            cityName={cityName}
            lang={lang}
          />

          <LinkingsGroup onlyPopularBlock linkings={linkings} city={city} lang={lang} />
        </LazyHydrate>

        {seoHtml && seoCategory?.description ? (
          <div
            className="Collection__seo"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: seoHtml,
            }}
          />
        ) : null}

        <StackedAccordions data={collection.faqs}>
          <Trans>FAQ</Trans>
        </StackedAccordions>

        {lang === "en" && (
          <StackedAccordions data={collection?.abouts} TitleTag="h2">
            <Trans>About {collection?.name}</Trans>
          </StackedAccordions>
        )}

        <SitemapBlock city={city} lang={lang} cityName={cityName} />

        <JsonLd
          item={{
            "@context": "https://schema.org",
            "@type": "Product",
            url: `https://wegotrip.${DNSZone}${currentPath}`,
            sku: city.id,
            name: city.name,
            description: city.meta_description,
            brand: { "@type": "Brand", name: "WeGoTrip" },
            image: city.preview,
            ...(reviews.count
              ? {
                  aggregateRating: {
                    "@type": "AggregateRating",
                    ratingValue: reviews.averageRating,
                    reviewCount: reviews.count,
                  },
                }
              : {}),
            ...(reviews.count
              ? {
                  review: reviews.reviews.map(review => ({
                    "@type": "Review",
                    author: { "@type": "Person", name: review.name },
                    datePublished: review.date,
                    description: review.text,
                    reviewRating: {
                      "@type": "Rating",
                      bestRating: "5",
                      ratingValue: review.rating,
                      worstRating: "1",
                    },
                  })),
                }
              : {}),
            ...(products.count
              ? {
                  offers: products.products.map(product => ({
                    "@type": "Offer",
                    price: product.price,
                    priceCurrency: product.currencyCode,
                    url: `https://wegotrip.${DNSZone}${getProductLink(lang, product)}`,
                    availability: "https://schema.org/InStock",
                  })),
                }
              : {}),
          }}
        />
        {lang === "en" && collection.faqs && (
          <JsonLd
            item={{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              mainEntity: collection.faqs.map(question => ({
                "@type": "Question",
                name: question.title,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: question.body,
                },
              })),
            }}
          />
        )}
      </div>
    </Root>
  );
}

Collection.getInitialProps = withRedirectToKnownLang(
  // eslint-disable-next-line no-unused-vars
  async ({ req, res, match, history, location, store, scrollToTop }) => {
    try {
      const cookies = get(req, "headers.cookie");
      const mobile = isMobileUserAgent(getUserAgent(req));
      store.dispatch({ type: types.FETCH_USER, cookies });

      const currency = await fetchDefaultCurrency(req);
      store.dispatch({ type: types.SET_DEFAULT_CURRENCY, defaultCurrency: currency });

      const { categoryId, cityId } = match.params;
      const lang = getCurrentLanguage(match.params.lang);

      const { data: city } = await Api.get(
        `/api/v2/cities/${cityId}/?${toQueryString({
          lang,
          expand: "h1,meta_title,meta_description,description",
          preorder: true,
        })}`,
        { lang },
      );
      store.dispatch({ type: types.FETCH_PRODUCTS, cityId, categoryId, lang });

      store.dispatch({
        type: types.FETCH_MOST_RECOMMENDED,
        payload: { city: cityId, category: categoryId, lang },
      });

      const {
        reviews: { count: reviewsCount },
      } = store.getState();
      if (!reviewsCount) {
        store.dispatch({
          type: types.FETCH_REVIEWS,
          lang,
          cityId,
          per_page: mobile ? 3 : 5,
        });
      }

      store.dispatch({
        type: types.FETCH_OVERALL_RATING,
        payload: { id: cityId, type: "city", lang },
      });

      let cityAttractionsWithoutTickets;
      if (city.country?.id) {
        cityAttractionsWithoutTickets = await Api.get(
          `/api/v3/attractions/?${toQueryString({
            lang,
            city_id: cityId,
            country_id: city.country.id,
            include_preorder: false,
            per_page: 20,
            sort_by: "popularity",
          })}&with_tickets=false`,
          { lang },
        );
      }

      store.dispatch({
        type: types.FETCH_CATEGORIES,
        payload: { cityId, lang },
      });

      store.dispatch({
        type: types.FETCH_INTERESTS,
        payload: { cityId, lang },
      });

      /**
       * Redirecting to main page if no tours presented
       * Runs only if `bubbling` query param is set
       */
      if (get(req, "query.bubbling") && !city.itemsCount) {
        return {
          redirectTo: reverseUrl("main", {
            lang,
          }),
        };
      }
      const linkings = await fetchLinkings({ lang, id: cityId });

      return {
        cityAttractionsWithoutTickets,
        city,
        linkings,
        mobile,
        lang,
        path: req?.originalUrl,
      };
    } catch (error) {
      // TODO: serve 500 for not 404 errors
      return { statusCode: 404 };
    }
  },
);

const mapStateToProps = ({ products, user, reviews, cities, filters }) => ({
  products,
  mostRecommended: products.mostRecommendedProducts,
  categories: cities.categories,
  interests: cities.interests,
  userId: user.user.id,
  reviews,
  overallCount: reviews.overallCount,
  filters,
});

export default connect(mapStateToProps)(Collection);
