import React from "react";
import { Plural } from "@lingui/macro";
import { CITY_COVER_WIDTH } from "../../constants/image/sizes";
import reverseUrl from "../../functions/reverseUrl";
import classNames from "../../functions/classNames";
import getIndependentScreenSize from "../../functions/screen/getIndependentScreenSize";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import CoverImage from "../CoverImage";
import Link from "../Link";
import getCityCardMaxWidth from "./getCityCardMaxWidth";
import "./CityCard.css";

/**
 * City card
 * @param {Object} $
 * @param {Number} $.id - id of the city
 * @param {Number} $.itemsCount - amount of tours in location
 * @param {String} $.className - additional className for the root Element
 * @param {String} $.name - city name
 * @param {String} $.slag - city id in the url
 * @param {String} $.preview - background image src
 * @param {Boolean} $.external - use <a> instead of <Link> to reach other bundle
 */
export function CityCard({
  id,
  name,
  slug,
  preview,
  itemsCount,
  attractions,
  className,
  external = false,
}) {
  const lang = useCurrentLanguage();

  const toursCount = (
    <span className="CityCard__description CityCard__count">
      {id === 300 ? (
        <Plural
          value={itemsCount}
          _0="# guides"
          one="# guide"
          few="# guides"
          many="# guides"
          other="# guides"
        />
      ) : (
        <Plural
          value={itemsCount}
          _0="# tours"
          one="# tour"
          few="# tours and tickets"
          many="# tours and tickets"
          other="# tours and tickets"
        />
      )}
    </span>
  );

  return (
    <div style={{ height: "100%" }} className="CityCard">
      <Link
        className={classNames("CityCard", attractions && "CityCard--with-attractions", className)}
        to={reverseUrl("city", {
          lang,
          cityId: id,
          citySlug: slug,
        })}
        external={external}
      >
        <span className={classNames("CityCard__cover")}>
          {preview ? (
            <CoverImage
              width={getCityCardMaxWidth(getIndependentScreenSize()) || CITY_COVER_WIDTH}
              src={preview}
              className="CityCard__image"
              alt=""
              aria-hidden="true"
            />
          ) : null}
          <span className="CityCard__title">{name}</span>
          {!attractions ? toursCount : null}
        </span>
      </Link>
      {attractions ? (
        <span className="CityCard__footer">
          <div>
            {attractions.length &&
              attractions.map(({ name: title, slug: attractionSlug, id: attractionId }) => (
                <Link
                  key={attractionSlug}
                  external
                  theme="black"
                  className="CityCard__link"
                  to={reverseUrl("attraction-simple", {
                    lang,
                    attractionSlug,
                    attractionId,
                  })}
                  target="_blank"
                >
                  {title}
                </Link>
              ))}
          </div>
          <span className="CityCard__link">{attractions ? toursCount : null}</span>
        </span>
      ) : null}
    </div>
  );
}
