/* eslint-disable react/self-closing-comp */
import React from "react";
import { connect } from "react-redux";
import { Plural, Trans } from "@lingui/macro";
import reverseUrl from "../../functions/reverseUrl";
import Button from "../Button";
import * as types from "../../stores/types";

import Link from "../Link";
import "./CityLinkSection.css";

const CityLinkSection = ({ city, lang, resetFilters }) => {
  const variantToursAndTickets = (
    <Plural
      value={city.itemsCount}
      _0="# tours & tickets"
      one="# tour & ticket"
      few="# tours & tickets"
      many="# tours & tickets"
      other="# tours & tickets"
    />
  );

  const handleClick = () => {
    resetFilters();
  };

  return (
    <Link
      to={reverseUrl("city", {
        lang,
        cityId: city.id,
        citySlug: city.slug,
      })}
      className="CityLinkSection"
      onClick={handleClick}
    >
      <div
        className="CityLinkSection__image"
        style={{ backgroundImage: `url(${city.cover})` }}
      ></div>
      <div className="CityLinkSection__gradient"></div>
      <div className="CityLinkSection__overlay">
        <div className="CityLinkSection__title">{city.name}</div>
        <Button
          theme="accent-full"
          className="CityLinkSection__overlay_tours-link"
          onClick={handleClick}
        >
          <Trans>See all {variantToursAndTickets}</Trans>
        </Button>
      </div>
    </Link>
  );
};

const mapDispatchToProps = dispatch => ({
  resetFilters: () => dispatch({ type: types.RESET_PRODUCTS_FILTERS }),
});

export default connect(null, mapDispatchToProps)(CityLinkSection);
