import React, { useRef, useState } from "react";
import { t } from "@lingui/macro";
import PopupOrDropdownAdapter from "../../PopupOrDropdownAdapter";
import { Icon } from "../../Icon";
import "./ActivitiesSort.css";
import Radio from "../../Radio";
import ChevronIcon from "../../ChevronIcon";

const ActivitiesSort = ({
  sortOrder,
  sortOrderText,
  handleSortChange,
  prefetchedCount,
  isMobile,
  i18n,
}) => {
  const [opened, setOpened] = useState(false);
  const dropdownRef = useRef(null);

  const sortOptions = [
    { value: "recommended", label: i18n._(t`Recommended`) },
    { value: "price", label: i18n._(t`Price: Low to High`) },
    { value: "-price", label: i18n._(t`Price: High to Low`) },
    { value: "rating", label: i18n._(t`Rating`) },
  ];

  const handleSortChangeAndClose = (value, label) => {
    handleSortChange(value, label);
    setOpened(false);
    if (dropdownRef.current) {
      dropdownRef.current.handleClose();
    }
  };

  const renderSortOptions = () => {
    return sortOptions.map(option => (
      <li key={option.value} onClick={() => handleSortChangeAndClose(option.value, option.label)}>
        <Icon
          name="done"
          className={sortOrder !== option.value ? "ActivitiesSort__sortMenu_icon-hidden" : ""}
        />
        <span className="ActivitiesSort__sortMenu_item">{option.label}</span>
      </li>
    ));
  };

  const renderRadioOptions = () => {
    return sortOptions.map(option => (
      <Radio
        key={option.value}
        checked={sortOrder === option.value}
        label={option.label}
        onChange={() => handleSortChangeAndClose(option.value, option.label)}
      />
    ));
  };

  return (
    <div className="ActivitiesSort">
      <div className="ActivitiesSort__activities">
        <Icon name="found-flag" />
        {i18n._(t`Activities found:`)}
        <span className="ActivitiesSort__activities-amount">{prefetchedCount}</span>
      </div>
      <div className="ActivitiesSort__sort">
        {!isMobile && i18n._(t`Sort by`)}
        {!isMobile && ":"}
        <PopupOrDropdownAdapter
          ref={dropdownRef}
          title={isMobile && i18n._(t`Sort by`)}
          ContentComponent={() =>
            isMobile ? (
              <div className="ActivitiesSort__radioMenu_items">{renderRadioOptions()}</div>
            ) : (
              <ul className="ActivitiesSort__sortMenu_items">{renderSortOptions()}</ul>
            )
          }
          triggerElement={
            <div className="ActivitiesSort__sort-button" onClick={() => setOpened(!opened)}>
              {sortOrderText}
              <ChevronIcon isOpen={opened} />
            </div>
          }
          isMobile={isMobile}
          align="right"
          bodyClassName="ActivitiesSort__sortMenu"
          onClick={() => {
            if (opened) {
              dropdownRef.current.handleClose();
            } else {
              dropdownRef.current.handleOpen();
            }
            setOpened(!opened);
          }}
          onClose={() => setOpened(false)}
          onStateChange={setOpened}
        />
      </div>
    </div>
  );
};

export default ActivitiesSort;
